import { useMemo } from 'react';
import { sortBy } from 'lodash';

import ControlledDropdown from '../../../modals/ControlledDropdown';

import type { Organization } from '../../../../../../types';
import type { Control } from 'react-hook-form';

interface Props {
  control: Control;
  defaultValue?: string;
  orgs: Organization[];
  required?: boolean;
}

export const SelectOrg = ({ control, orgs, defaultValue, required }: Props) => {
  const orgItems = useMemo(() => {
    const items = orgs.map((org) => ({
      label: `${org.name} (${org.id})`,
      value: org.id,
    }));

    return [
      {
        label: 'None',
        value: '',
      },
      ...sortBy(items, 'label'),
    ];
  }, [orgs]);

  return (
    <ControlledDropdown
      id='org'
      label='Organization'
      defaultValue={defaultValue}
      items={orgItems}
      control={control}
      required={required}
    />
  );
};
