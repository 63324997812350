import { useEffect, useRef } from 'react';
import { isEmpty } from 'lodash';

import type { RegionConfig } from '@inspiren-monorepo/hilq/api-contracts';

import { drawRegion } from '../helpers';

type CanvasProps = React.DetailedHTMLProps<
  React.CanvasHTMLAttributes<HTMLCanvasElement>,
  HTMLCanvasElement
>;

interface Props extends CanvasProps {
  handleDrawingPointChange: (e: React.MouseEvent<HTMLElement>) => void;
  highlightedRegionId?: string;
  regions: RegionConfig[];
  handleMouseMove: (e: React.MouseEvent<HTMLElement>) => void;
  handleMouseUp: (e: React.MouseEvent<HTMLElement>) => void;
}

export const Canvas = ({
  width,
  height,
  handleDrawingPointChange,
  regions,
  highlightedRegionId,
  handleMouseMove,
  handleMouseUp,
}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;

    if (canvas) {
      const ctx = canvas.getContext('2d');

      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (!isEmpty(regions)) {
          for (const shape of regions) {
            const isHighlighted = shape.id === highlightedRegionId;

            if (!isEmpty(shape)) {
              drawRegion(ctx, shape.points, shape.type, isHighlighted);
            }
          }
        }
      }
    }
  }, [highlightedRegionId, regions]);

  return (
    <canvas
      ref={canvasRef}
      width={width}
      height={height}
      style={{ position: 'absolute', top: 0, left: 0, zIndex: 1000000 }}
      onMouseDown={handleDrawingPointChange}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    />
  );
};
