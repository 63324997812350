import { useQuery } from '@tanstack/react-query';

import { getUnits } from '../data-access/getUnits';

import type { Unit } from '../../../../types';
import type { UseQueryOptions } from '@tanstack/react-query';

type Props = Omit<
  UseQueryOptions<Unit[], Error, Unit[], string[]>,
  'queryKey' | 'queryFn'
>;

export const useUnits = (props: Props = {}) =>
  useQuery({
    ...props,
    queryKey: ['units'],
    queryFn: getUnits,
  });
