import { subDays } from 'date-fns';
import { isNil } from 'lodash';

export const isPastImageExpiration = (
  endDate: Date | null,
  selectedUnit: any | null,
): boolean =>
  Boolean(
    endDate &&
      selectedUnit &&
      !isNil(selectedUnit.imageExp) &&
      endDate < subDays(new Date(), selectedUnit.imageExp),
  );
