import type React from 'react';
import { Box, Stack } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';

import AddModal from './modals/AddModal';

import type {
  OnSubmitFormModal,
  RenderFormModal,
} from './modals/FormModalBase';
import type { DataFields } from './types/DataFields';

type Props<FieldTypes> = {
  itemName: string;
  fields: DataFields<FieldTypes>;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  onAddSubmit: OnSubmitFormModal<FieldTypes>;
  onAddOpen?: () => void;
  addLoading?: boolean;
  addError?: string | null;
  addExport?: boolean;
  extraButtons?: React.ReactNode;
  tableHeader?: React.ReactNode;
  disableAddButton?: boolean;
  showAddButton?: boolean;
  renderModal?: RenderFormModal<FieldTypes>;
};

const InternalToolbar = <FieldTypes,>({
  itemName,
  fields,
  setAnchorEl,
  onAddSubmit,
  onAddOpen,
  addLoading = false,
  addError = null,
  addExport = true,
  extraButtons,
  tableHeader,
  disableAddButton,
  showAddButton = true,
  renderModal,
}: Props<FieldTypes>) => (
  <GridToolbarContainer>
    <Stack direction='row' flexWrap='wrap' width='100%'>
      <Stack
        direction='row'
        mr={2}
        mb={1}
        gap={1}
        sx={{ flex: 1 }}
        alignItems='center'
      >
        {tableHeader}
        {showAddButton && (
          <AddModal
            itemName={itemName}
            fields={fields}
            onSubmit={onAddSubmit}
            onOpen={onAddOpen}
            loading={addLoading}
            error={addError}
            disabled={disableAddButton}
            renderModal={renderModal}
          />
        )}
        {extraButtons}
      </Stack>
      <Box ml='auto'>
        <GridToolbarColumnsButton ref={setAnchorEl} />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        {addExport && (
          <GridToolbarExport
            csvOptions={{ fileName: `${itemName}Data` }}
            printOptions={{
              hideFooter: true,
              hideToolbar: true,
            }}
          />
        )}
      </Box>
    </Stack>
  </GridToolbarContainer>
);

export default InternalToolbar;
