import { sortBy } from 'lodash';

import type { RoleMap } from '../../../../../../types';

export type RoleOption = {
  value: string;
  label: string;
};

export const getRoleOptions = (
  isAdminRole: boolean,
  roleMap: RoleMap,
): RoleOption[] => {
  const filteredRoleMap: RoleMap = isAdminRole
    ? roleMap
    : Object.fromEntries(
        Object.entries(roleMap)
          .filter(([_, value]) => value.template !== 'Admin')
          .map(([roleId, value]) => [roleId, value]),
      );

  const roles: RoleOption[] = Object.entries(filteredRoleMap).map(
    ([roleId, value]) => ({
      value: roleId,
      label: value.displayName,
    }),
  );

  return sortBy(roles, (r) => r.label.toLocaleLowerCase());
};
