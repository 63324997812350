import { Api } from '../../../../../utility/client';

import type { Unit } from '../../../../../../types';
import type { UnitUpdate } from '../../../data-access/types/UnitUpdate';

export const putUnit = async (unit: UnitUpdate) => {
  try {
    const res = await Api.put<Unit>(
      `/admin/v1/units/${unit.floor}-${unit.name}`,
      unit,
    );

    return res?.data;
  } catch (error) {
    console.error('error editing unit');
    throw error;
  }
};
