import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useInterval } from 'usehooks-ts';

import { getUpstreamErrorFromQuery } from '../../../helpers/getUpstreamErrorFromQuery';

import type { MutationFunction } from '@tanstack/react-query';

interface Params {
  resendVerificationCodeMutationFn: MutationFunction<void, void>;
}

export const useVerificationCodeCooldown = ({
  resendVerificationCodeMutationFn,
}: Params) => {
  const [cooldown, setCooldown] = useState(0);

  const resendText =
    cooldown > 0
      ? `Re-send confirmation code in ${cooldown}s`
      : 'Re-send confirmation code';

  const {
    mutate: requestVerificationCodeResend,
    isPending,
    error,
  } = useMutation({
    mutationFn: resendVerificationCodeMutationFn,
    onSuccess: () => setCooldown(60),
  });

  useInterval(
    () => {
      setCooldown((prev) => (prev > 0 ? prev - 1 : prev));
    },
    cooldown > 0 ? 1000 : null,
  );

  useEffect(() => {
    const upstreamError = getUpstreamErrorFromQuery(error);

    if (
      upstreamError?.errorCode === 'MOBILE_PHONE_CONFIRMATION_CODE_COOLDOWN'
    ) {
      const until = upstreamError.extras?.until;

      if (typeof until === 'number' && until > 0) {
        setCooldown(until);
      }
    }
  }, [error]);

  return {
    isPending,
    requestVerificationCodeResend,
    resendText,
    cooldown,
  };
};
