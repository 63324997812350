import { useRef } from 'react';
import { Box, CircularProgress, Stack } from '@mui/material';

import type { RegionConfig } from '@inspiren-monorepo/hilq/api-contracts';

import { Canvas } from './Canvas';

import { RoomInfo } from '../RoomInfo';

interface Props {
  roomName?: string;
  baseId?: string;
  awsAccountId?: string;
  cursor: string;
  isLoading: boolean;
  handleDrawingPointChange: (e: React.MouseEvent<HTMLElement>) => void;
  regions: RegionConfig[];
  handleMouseMove: (e: React.MouseEvent<HTMLElement>) => void;
  handleMouseUp: (e: React.MouseEvent<HTMLElement>) => void;
  highlightedRegionId?: string;
  lastSuccessfulImage?: string;
  width: number;
  height: number;
  app: 'hilq' | 'virtual-care';
}

export const ZoneUpdateImage = ({
  cursor,
  roomName,
  baseId,
  awsAccountId,
  isLoading,
  handleDrawingPointChange,
  highlightedRegionId,
  regions,
  handleMouseMove,
  handleMouseUp,
  lastSuccessfulImage,
  width,
  height,
  app,
}: Props) => {
  const canvasRef = useRef(null);
  return (
    <Box
      sx={{
        cursor,
      }}
    >
      <Box
        sx={{
          width: `${width}px`,
          height: `${height}px`,
          position: 'relative',
          backgroundColor: 'primary.main',
        }}
      >
        <Stack
          direction='row'
          sx={{
            position: 'absolute',
            width: '100%',
            bottom: 30,
            padding: '0px 37px',
            justifyContent: 'start',
            alignItems: 'center',
          }}
        >
          <RoomInfo
            app={app}
            roomName={roomName}
            baseId={baseId}
            awsAccountId={awsAccountId}
          />
        </Stack>
        {!lastSuccessfulImage || isLoading ? (
          <CircularProgress
            color='inherit'
            sx={{ top: '50%', left: '50%', position: 'absolute' }}
          />
        ) : (
          <>
            <img
              src={lastSuccessfulImage || ''}
              width='100%'
              height='100%'
              alt=''
            />
            <Canvas
              ref={canvasRef}
              width={width}
              height={height}
              handleDrawingPointChange={handleDrawingPointChange}
              highlightedRegionId={highlightedRegionId}
              regions={regions}
              handleMouseMove={handleMouseMove}
              handleMouseUp={handleMouseUp}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
