import { useMemo } from 'react';
import { capitalize, isEmpty, isNil, lowerCase, sortBy } from 'lodash';

import type { WasabiImage } from '@inspiren-monorepo/shared-react';
import {
  BATHROOM_OCCUPIED_ALERT,
  UserEventType,
} from '@inspiren-monorepo/shared-types';

import { useBathroomEvents } from './useBathroomEvents';
import { useNotifications } from './useNotifications';
import { useOfflineEvents } from './useOfflineEvents';
import { useStaffEvents } from './useStaffEvents';
import { useUserEvents } from './useUserEvents';

import { collapseCrowdedMarks } from '../../../utility/helpers/helpers';
import { getPositionFromDate } from '../../../utility/helpers/time';

import type { MarkData, RoleMap } from '../../../../types';

interface UseMarksParams {
  images: WasabiImage[] | null;
  showStaffEventMarks: boolean;
  showNotifMarks: boolean;
  spacePerMark: number | null;
  startDate: Date | null;
  endDate: Date | null;
  roleMap: RoleMap;
}

export const useMarks = ({
  images,
  showStaffEventMarks,
  showNotifMarks,
  spacePerMark,
  startDate,
  endDate,
  roleMap,
}: UseMarksParams) => {
  const { data: staffEvents } = useStaffEvents();
  const { data: notifications } = useNotifications();
  const { data: offlineEvents } = useOfflineEvents();
  const { data: userEvents } = useUserEvents();
  const { data: bathroomEvents } = useBathroomEvents();

  const marks = useMemo(() => {
    const eventMarks: MarkData[] = [];
    const notifMarks: MarkData[] = [];
    const liveViewOpenMarks: MarkData[] = [];
    const augiDisabledMarks: MarkData[] = [];
    const privacyModeEnabledMarks: MarkData[] = [];
    const offlineMarks: MarkData[] = [];
    const bathroomMarks: MarkData[] = [];

    const virtualCurtainEvents = userEvents?.filter(
      (event) => event.event === UserEventType.PrivacyModeOn,
    );

    const liveViewOpened = userEvents?.filter(
      (event) =>
        event.event === UserEventType.LiveViewOpened ||
        event.event === UserEventType.ExpandedLiveViewOpened ||
        event.event === UserEventType.AlertLiveViewOpened,
    );

    const augiDisabled = userEvents?.filter(
      (event) => event.event === UserEventType.AugiDisabled,
    );

    const augiEnabled = userEvents?.filter(
      (event) => event.event === UserEventType.AugiEnabled,
    );

    offlineEvents?.forEach((event) => {
      const start = new Date(event.start);

      // if the event starts before the start date, set the time position to 1
      const timePosition =
        startDate && start < startDate ? 1 : getPositionFromDate(images, start);

      // if the event ends after the end date, set the end position to the last image
      const end = event.end ? new Date(event.end) : new Date();
      const endPosition = getPositionFromDate(images, end);

      // the span is the difference between the start and end positions
      if (timePosition && endPosition) {
        offlineMarks.push({
          value: timePosition,
          time: start,
          label: 'Offline Event',
          type: 'offlineEvent',
          span: endPosition - timePosition,
        });
      }
    });

    // get the most recent augiDisabled and augiEnabled events
    const mostRecentAugiDisabled = augiDisabled?.at(0)?.time;
    const mostRecentAugiEnabled = augiEnabled?.at(0)?.time;

    if (showStaffEventMarks) {
      // if there are no augiDisabled events or the most recent augiDisabled event is after the most recent augiEnabled event
      if (
        ((isEmpty(augiDisabled) && !isEmpty(augiEnabled)) ||
          (!isEmpty(augiDisabled) &&
            !isEmpty(augiEnabled) &&
            new Date(mostRecentAugiDisabled || 0) >
              new Date(mostRecentAugiEnabled || 0))) &&
        mostRecentAugiEnabled
      ) {
        const time = new Date(mostRecentAugiEnabled);
        const timePosition = getPositionFromDate(images, time);

        // the span is from start to the next augiEnabled event
        if (timePosition) {
          augiDisabledMarks.push({
            value: 0,
            time,
            label: 'AUGi Disabled',
            type: 'userEvent',
            span: timePosition - 0,
          });
        }
      } else {
        augiDisabled?.forEach((event) => {
          const time = new Date(event.time);
          const timePosition = getPositionFromDate(images, time);

          const nextAugiEnabled = augiEnabled?.find(
            (e) => new Date(e.time) > time,
          );

          const nextAugiEnabledTime = nextAugiEnabled
            ? new Date(nextAugiEnabled.time)
            : new Date();

          const nextAugiEnabledPosition = getPositionFromDate(
            images,
            nextAugiEnabledTime,
          );

          if (timePosition) {
            augiDisabledMarks.push({
              value: timePosition,
              time,
              label: 'AUGi Disabled',
              type: 'userEvent',
              span: nextAugiEnabledPosition! - timePosition,
            });
          }
        });
      }

      liveViewOpened?.forEach((event) => {
        const time = new Date(event.time);
        const timePosition = getPositionFromDate(images, time);

        if (timePosition) {
          liveViewOpenMarks.push({
            value: timePosition,
            time,
            label:
              event.event === UserEventType.AlertLiveViewOpened
                ? 'Alert Live View Opened'
                : event.event === UserEventType.ExpandedLiveViewOpened
                  ? 'Expanded Live View Opened'
                  : 'Live View Opened',
            type: 'userEvent',
          });
        }
      });

      virtualCurtainEvents?.forEach((event) => {
        const startTime = new Date(event.time);
        const endTime = event.timeOut ? new Date(event.timeOut) : new Date();
        const startPosition = getPositionFromDate(images, startTime);
        const endPositon = getPositionFromDate(images, endTime);

        if (!isNil(startPosition) && !isNil(endPositon)) {
          privacyModeEnabledMarks.push({
            value: startPosition,
            time: startTime,
            label: 'Virtual Curtain Enabled',
            type: 'userEvent',
            span: endPositon - startPosition,
          });
        }
      });

      staffEvents?.forEach((event) => {
        if (event.timeIn) {
          const timeIn = new Date(event.timeIn);

          if (startDate && timeIn >= startDate) {
            const timeInPosition = getPositionFromDate(images, timeIn);

            if (timeInPosition) {
              eventMarks.push({
                value: timeInPosition,
                time: timeIn,
                label: `${event.name} entered`,
                type: 'staff-event',
              });
            }
          }
        }

        if (event.timeOut) {
          const timeOut = new Date(event.timeOut);

          if (endDate && timeOut <= endDate) {
            const timeOutPosition = getPositionFromDate(images, timeOut);

            if (timeOutPosition) {
              eventMarks.push({
                value: timeOutPosition,
                time: timeOut,
                label: `${event.name} left`,
                type: 'staff-event',
              });
            }
          }
        }
      });

      // we only show bathroom events if there is a bathroom notification
      if (notifications && showNotifMarks) {
        const bathroomNotifs = notifications.filter(
          (notif) => notif.type === BATHROOM_OCCUPIED_ALERT,
        );

        bathroomEvents?.forEach((event) => {
          const { timeIn, timeOut } = event;
          const endTime = timeOut ? new Date(timeOut) : new Date();

          const foundBathroomNotif = bathroomNotifs.some((notif) => {
            const promoted = new Date(notif.promotedOn);
            return (
              promoted >= timeIn &&
              startDate &&
              promoted >= startDate &&
              promoted <= endTime
            );
          });

          if (foundBathroomNotif) {
            const timeInPosition = getPositionFromDate(images, timeIn);
            const timeOutPosition = getPositionFromDate(images, endTime);

            if (timeInPosition && timeOutPosition) {
              bathroomMarks.push({
                value: timeInPosition,
                time: event.timeIn,
                label: 'Bathroom Occupied',
                type: 'bathroomEvent',
                span: timeOutPosition - timeInPosition,
              });
            }
          }
        });
      }
    }

    if (showNotifMarks) {
      notifications?.forEach((notif) => {
        const promoted = new Date(notif.promotedOn);

        const getResolvedLabel = () =>
          notif.resolvedByName === 'expired'
            ? 'Notification expired'
            : `Notification resolved by ${
                isNil(roleMap[notif?.resolvedByRoleId || '']?.displayName)
                  ? 'system'
                  : notif.resolvedByName
              }`;

        if (startDate && promoted >= startDate) {
          const sentAtPosition = getPositionFromDate(images, promoted);

          if (sentAtPosition) {
            notifMarks.push({
              value: sentAtPosition,
              time: promoted,
              label: `${capitalize(lowerCase(notif.type))} notification sent`,
              type: 'notification',
            });
          }
        }

        if (notif.resolvedAt) {
          const resolved = new Date(notif.resolvedAt);

          if (endDate && resolved <= endDate) {
            const resolvedAtPosition = getPositionFromDate(images, resolved);

            if (resolvedAtPosition) {
              notifMarks.push({
                value: resolvedAtPosition,
                time: resolved,
                label: getResolvedLabel(),
                type: 'notification',
              });
            }
          }
        }
      });
    }

    const allMarks = sortBy(
      [...eventMarks, ...notifMarks, ...liveViewOpenMarks],
      'value',
    );

    const spaceThreshold = 12;

    // if there are any other marks within the span of a offlineMark remove them
    // reason: offline marks are the most important and should be visible and no other marks should be visible within the span of a offline mark
    offlineMarks.forEach((offlineMark) => {
      allMarks.forEach((mark) => {
        if (
          mark.value >= offlineMark.value &&
          mark.value <= offlineMark.value + offlineMark.span!
        ) {
          allMarks.splice(allMarks.indexOf(mark), 1);
        }
      });

      // same for augiDisabledMarks and privacyModeEnabledMarks
      augiDisabledMarks.forEach((augiDisabledMark) => {
        if (
          augiDisabledMark.value >= offlineMark.value &&
          augiDisabledMark.value <= offlineMark.value + offlineMark.span!
        ) {
          augiDisabledMarks.splice(
            augiDisabledMarks.indexOf(augiDisabledMark),
            1,
          );
        }
      });

      privacyModeEnabledMarks.forEach((privacyModeEnabledMark) => {
        if (
          privacyModeEnabledMark.value >= offlineMark.value &&
          privacyModeEnabledMark.value <= offlineMark.value + offlineMark.span!
        ) {
          privacyModeEnabledMarks.splice(
            privacyModeEnabledMarks.indexOf(privacyModeEnabledMark),
            1,
          );
        }
      });
    });

    // same for augiDisabledMarks
    augiDisabledMarks.forEach((augiDisabledMark) => {
      allMarks.forEach((mark) => {
        if (
          mark.value >= augiDisabledMark.value &&
          mark.value <= augiDisabledMark.value + augiDisabledMark.span!
        ) {
          allMarks.splice(allMarks.indexOf(mark), 1);
        }
      });

      privacyModeEnabledMarks.forEach((privacyModeEnabledMark) => {
        if (
          privacyModeEnabledMark.value >= augiDisabledMark.value &&
          privacyModeEnabledMark.value <=
            augiDisabledMark.value + augiDisabledMark.span!
        ) {
          privacyModeEnabledMarks.splice(
            privacyModeEnabledMarks.indexOf(privacyModeEnabledMark),
            1,
          );
        }
      });
    });

    // same for privacyModeEnabledMarks
    privacyModeEnabledMarks.forEach((privacyModeEnabledMark) => {
      allMarks.forEach((mark) => {
        if (
          mark.value >= privacyModeEnabledMark.value &&
          mark.value <=
            privacyModeEnabledMark.value + privacyModeEnabledMark.span!
        ) {
          allMarks.splice(allMarks.indexOf(mark), 1);
        }
      });
    });

    const finalMarks = collapseCrowdedMarks(
      allMarks,
      spacePerMark,
      spaceThreshold,
    );

    // augi offline, augi disabled, virtual curtain enabled
    return [
      ...offlineMarks,
      ...augiDisabledMarks,
      ...privacyModeEnabledMarks,
      ...finalMarks,
      ...bathroomMarks,
    ];
  }, [
    images,
    staffEvents,
    notifications,
    userEvents,
    offlineEvents,
    bathroomEvents,
    showStaffEventMarks,
    showNotifMarks,
    spacePerMark,
    startDate,
    endDate,
  ]);

  return marks;
};
