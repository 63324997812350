import { Api } from '../../../utility/client';

import type { AdminOrganizationSettings } from '../../../../types';

export const putOrgSettings = async (settings: AdminOrganizationSettings) => {
  const res = await Api.put<AdminOrganizationSettings>(
    `/admin/v1/orgs/${settings.org}/settings`,
    settings,
  );

  return res.data;
};
