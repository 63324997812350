import { Api } from '../../../utility/client';

import type { Role } from '../../../../types';

export const postRole = async (role: Role) => {
  const res = await Api.post('/admin/v1/roles', {
    ...role,
    id: `${role.displayName}`,
  });

  return res.data;
};
