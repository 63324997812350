import { useCallback, useEffect, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import type { ResponsiveStyleValue } from '@mui/system';
import { useQuery } from '@tanstack/react-query';
import { sortBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import type {
  RegionConfig,
  Point,
} from '@inspiren-monorepo/hilq/api-contracts';
import type { DrawingRegion } from '@inspiren-monorepo/virtual-care/api-contracts';

import { CanvasControls } from './components/CanvasControls';
import { ZoneUpdateImage } from './components/ZoneUpdateImage';
import {
  addDistanceTravelled,
  addPointToRegion,
  checkIfInverted,
  convertToAbsolute,
  getDragRegionId,
  getPointWithin15Pixels,
  movePoint,
  getCornerArrayForInversion,
  snapToEdge,
  isOverTheEdge,
} from './helpers';

import type { CanvasAction } from './types';
import type { QueryKey } from '@tanstack/react-query';

export interface ZoneUpdateProps {
  direction?: ResponsiveStyleValue<'column' | 'column-reverse'>;
  isSavePending: boolean;
  imagesIsLoading: boolean;
  lastSuccessfulImage?: string;
  regionConfigParams: {
    queryKey: QueryKey;
    queryFn: () => Promise<RegionConfig[]>;
  };
  saveRegions: (allRegions: RegionConfig[]) => void;
  showControls: boolean;
  isAdminView?: boolean;
  handleCancel: () => void;
  roomName?: string;
  baseId?: string;
  awsAccountId?: string;
  app: 'hilq' | 'virtual-care';
  width: number;
  height: number;
}

export const ZoneUpdate = (props: ZoneUpdateProps) => {
  const {
    direction = 'column',
    handleCancel,
    imagesIsLoading,
    lastSuccessfulImage,
    regionConfigParams,
    showControls,
    isAdminView,
    isSavePending,
    roomName,
    baseId,
    awsAccountId,
    app,
    saveRegions,
    width,
    height,
  } = props;

  // this state is for when the user is dragging the mouse
  const [dragState, setDragState] = useState<boolean>(false);

  // this is state where the user has clicked and is dragging the mouse
  const [dragStart, setDragStart] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });

  const [cursor, setCursor] = useState('crosshair');

  // this is state is which region the user clicked
  const [dragRegionId, setDragRegionId] = useState<string | null>(null);

  // this is state is which point the user selected if they clicked one
  const [selectedPoint, setSelectedPoint] = useState<Point | null>(null);
  // this is state is which region the user is drawing
  const [drawingRegionId, setDrawingRegionId] = useState<string | undefined>();

  const [hoverRegionId, setHoverRegionId] = useState<string | undefined>();

  // this is state is which action the user is doing on the canvas
  const [canvasAction, setCanvasAction] = useState<CanvasAction | undefined>(
    'move',
  );
  // these state are the points or the regions

  const [lockExitIgnore, setLockExitIgnore] = useState<boolean>(true);

  const [addSequential, setAddSequential] = useState<boolean>(false);

  const [regions, setRegions] = useState<RegionConfig[]>([]);

  const {
    isLoading: isGetLoading,
    data,
    isSuccess: isGetSuccess,
  } = useQuery<RegionConfig[]>({
    ...regionConfigParams,
    refetchOnMount: true,
  });

  const regionSortOrder = {
    bed: 0,
    chair: 1,
    exit: 2,
    ignore: 3,
    bedEdge: 4,
  };

  const drawingRegionIndex = useMemo(
    () =>
      regions.findIndex((regionConfig) => regionConfig.id === drawingRegionId),
    [drawingRegionId, regions],
  );

  const dragRegionIndex = useMemo(
    () => regions.findIndex((regionConfig) => regionConfig.id === dragRegionId),
    [dragRegionId, regions],
  );

  const selectedPointRegionIndex = useMemo(
    () =>
      regions.findIndex(
        (regionConfig) => regionConfig.id === selectedPoint?.id,
      ),
    [selectedPoint, regions],
  );

  const canInvertRegion = useMemo(
    () =>
      regions[drawingRegionIndex]?.type === 'ignore' &&
      !checkIfInverted(
        regions[drawingRegionIndex]?.points || [],
        width,
        height,
      ),
    [drawingRegionIndex, regions],
  );

  const { editableRegions, flattenedRegions, sortedRegions } = useMemo(() => {
    const editable =
      isAdminView && !lockExitIgnore
        ? regions
        : regions.filter(
            (region) => region.type !== 'ignore' && region.type !== 'exit',
          );

    const sorted = sortBy(editable, (a) => regionSortOrder[a.type]);

    const flattened = editable
      .map((regionConfig) =>
        regionConfig.points.map((point) => ({ ...point, id: regionConfig.id })),
      )
      .flat();

    return {
      editableRegions: editable,
      sortedRegions: sorted,
      flattenedRegions: flattened,
    };
  }, [regions, isAdminView, lockExitIgnore]);

  // create a useMemo that turns true or false if allPoints are empty
  const allPointsEmpty = useMemo(
    () => !regions?.some((region) => region.points?.length),
    [regions],
  );

  const highlightedRegionId = useMemo(
    () => hoverRegionId || drawingRegionId,
    [hoverRegionId, drawingRegionId],
  );

  useEffect(() => {
    switch (canvasAction) {
      case 'draw':
        return setCursor('crosshair');
      case 'move':
        return setCursor('move');
      default:
        return setCursor('default');
    }
  }, [canvasAction]);

  useEffect(() => {
    if (isGetSuccess && Array.isArray(data)) {
      const adjustedPoints: RegionConfig[] = data.map((regionConfig) => {
        const regionId = uuidv4();
        return {
          id: regionId,
          type: regionConfig.type,
          points: (regionConfig.points || []).map((point) => ({
            ...convertToAbsolute(point, width, height),
            id: regionId,
          })),
        };
      });

      const visibleRegions = isAdminView
        ? adjustedPoints
        : adjustedPoints.filter((region) => region.type !== 'exit');

      setRegions(visibleRegions);
    }
  }, [data, isGetSuccess]);

  //  this is the function that is called when the user clicks which region they want to draw
  const handleDrawingRegionChange = useCallback(
    (_event: React.MouseEvent<HTMLElement>, newDrawingRegionId: string) => {
      if (drawingRegionId === 'more') {
        return;
      }

      setDrawingRegionId(newDrawingRegionId);
      setCanvasAction('draw');
    },
    [],
  );

  const handleAddRegion = useCallback((regionType: DrawingRegion) => {
    const newRegionId = uuidv4();

    setRegions((prevRegions) => [
      ...prevRegions,
      {
        id: newRegionId,
        type: regionType,
        points: [],
      },
    ]);

    setCanvasAction('draw');
    setDrawingRegionId(newRegionId);
  }, []);

  const handleRegionHover = useCallback((regionId?: string) => {
    setHoverRegionId(regionId);
  }, []);

  // this is the function that is called when the user clicks which action they want to do on the canvas
  const handleCanvasActionChange = useCallback(
    (newCanvasAction?: CanvasAction) => {
      setCanvasAction(newCanvasAction);
      setDrawingRegionId(undefined);
    },
    [],
  );

  // this is the function that is called when the user clicks the clear button
  const handleClearSelectedRegion = useCallback(() => {
    // we check what region the user has selected and clear that region
    setRegions(
      regions.filter((regionConfig) => regionConfig.id !== drawingRegionId),
    );

    setDrawingRegionId(undefined);
    setCanvasAction(undefined);
  }, [drawingRegionId, regions]);

  // this is the function that is called when the user clicks the clear all button
  const handleClearAllRegions = useCallback(() => {
    const newRegions = regions.filter(
      (regionConfig) =>
        lockExitIgnore &&
        (regionConfig.type === 'ignore' || regionConfig.type === 'exit'),
    );

    setRegions(newRegions);
    setCanvasAction(undefined);
  }, [regions, lockExitIgnore]);

  const updateRegionInArray = useCallback(
    (newRegion: RegionConfig, regionIndex: number) => {
      setRegions([
        ...regions.slice(0, regionIndex),
        newRegion,
        ...regions.slice(regionIndex + 1),
      ]);
    },
    [regions],
  );

  const getXAndY = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    return { x, y };
  }, []);

  // this is the function that is called when the user clicks down on the mouse
  const handleDrawingPoint = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      // get the x and y coordinates of the click
      const { x, y } = getXAndY(e);
      // if the user has selected the move action

      if (canvasAction === 'move') {
        // set the drag state to true
        setDragState(true);
        // set the drag start state
        setDragStart({ x, y });

        // check if there is a point within 15 pixels of the click
        const newSelectedPoint = getPointWithin15Pixels(flattenedRegions, x, y);

        // if there is a point set the selected point state to first closest point
        if (newSelectedPoint) {
          setSelectedPoint(newSelectedPoint);
        }

        // check if points are within the regions
        const newDragRegionId = getDragRegionId(editableRegions, x, y);

        if (newDragRegionId) {
          setDragRegionId(newDragRegionId);
        }

        if (newDragRegionId || newSelectedPoint) {
          setCursor('grabbing');
        }
      }
      // if the user has selected the draw action
      else if (canvasAction === 'draw' && drawingRegionIndex > -1) {
        const drawingRegion = regions[drawingRegionIndex];

        const xCoordinate = snapToEdge(x, width);
        const yCoordinate = snapToEdge(y, height);

        // add the point to the appropriate region
        const newPoint = {
          x: xCoordinate,
          y: yCoordinate,
          region: drawingRegion.type,
          id: drawingRegion.id,
        };

        const newPoints = addSequential
          ? [...drawingRegion.points, newPoint]
          : addPointToRegion(drawingRegion.points, newPoint, width, height);

        const newRegion = {
          ...drawingRegion,
          points: newPoints,
        };

        updateRegionInArray(newRegion, drawingRegionIndex);
      }
    },
    [canvasAction, drawingRegionId, regions, editableRegions, addSequential],
  );

  const updateSelectedPoint = useCallback(
    (newPoint: Point, points: Point[]) =>
      points.map((point) =>
        point.x === selectedPoint?.x && point.y === selectedPoint?.y
          ? newPoint
          : point,
      ),
    [selectedPoint],
  );

  // this is the function that is called when the user moves the mouse
  const handleMouseMove = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const { x, y } = getXAndY(e);
      const dx = x - dragStart.x;
      const dy = y - dragStart.y;

      if (dragState) {
        setDragStart({ x, y });

        // a region was selected, and there is no selected point
        if (!selectedPoint && dragRegionIndex > -1) {
          const dragRegion = regions[dragRegionIndex];

          const newRegion = {
            ...dragRegion,
            points: addDistanceTravelled(
              dragRegion.points,
              dx,
              dy,
              width,
              height,
            ),
          };

          updateRegionInArray(newRegion, dragRegionIndex);
        }
        // if drag state is true and there is a selected point within 15 pixels
        else if (selectedPointRegionIndex > -1) {
          const selectedPointRegion = regions[selectedPointRegionIndex];

          const overTheEdge = isOverTheEdge(
            selectedPoint as Point,
            dx,
            dy,
            width,
            height,
          );

          if (!overTheEdge) {
            const newPoint = movePoint(selectedPoint as Point, dx, dy);

            const newRegion = {
              ...selectedPointRegion,
              points: updateSelectedPoint(newPoint, selectedPointRegion.points),
            };

            setSelectedPoint(newPoint);
            updateRegionInArray(newRegion, selectedPointRegionIndex);
          }
        }
      } else if (cursor !== 'crosshair') {
        const mouseOverPoint = Boolean(
          getPointWithin15Pixels(flattenedRegions, x, y),
        );

        const mouseOverRegion = Boolean(getDragRegionId(editableRegions, x, y));

        if (mouseOverPoint || mouseOverRegion) {
          if (cursor !== 'grab') {
            setCursor('grab');
          }
        } else if (cursor === 'grab') {
          setCursor('move');
        }
      }
    },
    [
      cursor,
      dragState,
      dragStart,
      drawingRegionIndex,
      selectedPoint,
      dragRegionId,
      regions,
      editableRegions,
      flattenedRegions,
    ],
  );

  // this is the function that is called when the user releases the mouse
  const handleMouseUp = useCallback(() => {
    if (dragState) {
      if (selectedPoint) {
        const selectedPointRegion = regions[selectedPointRegionIndex];

        const xCoordinate = snapToEdge(selectedPoint.x, width);
        const yCoordinate = snapToEdge(selectedPoint.y, height);

        // add the point to the appropriate region
        const newPoint = {
          x: xCoordinate,
          y: yCoordinate,
          region: selectedPointRegion.type,
          id: selectedPointRegion.id,
        };

        const newRegion = {
          ...selectedPointRegion,
          points: updateSelectedPoint(newPoint, selectedPointRegion.points),
        };

        updateRegionInArray(newRegion, selectedPointRegionIndex);
      }

      setCursor((oldCursor) => (oldCursor === 'grabbing' ? 'grab' : oldCursor));
      setDragState(false);
      setSelectedPoint(null);
      setDragRegionId(null);
    }
  }, [canvasAction, dragState, selectedPoint, selectedPointRegionIndex]);

  const handleUpdate = useCallback(() => {
    const regionsToSave = isAdminView ? regions : editableRegions;
    saveRegions(regionsToSave);
  }, [regions, editableRegions]);

  const handleLockExitIgnore = useCallback(() => {
    setLockExitIgnore((prevLock) => !prevLock);
  }, []);

  const handleInvertRegion = useCallback(() => {
    if (drawingRegionIndex === -1) {
      return;
    }

    const drawingRegion = regions[drawingRegionIndex];

    const isInverted = checkIfInverted(drawingRegion.points, width, height);

    if (drawingRegion.type !== 'ignore' || isInverted) {
      return;
    }

    const fullCirclePoints = [
      ...drawingRegion.points,
      { ...drawingRegion.points[0] },
    ];

    const cornerPoints = getCornerArrayForInversion(
      fullCirclePoints[0],
      width,
      height,
    );

    const newRegion = {
      ...drawingRegion,
      points: [...fullCirclePoints, ...cornerPoints],
    };

    updateRegionInArray(newRegion, drawingRegionIndex);
  }, [regions, drawingRegionIndex]);

  useEffect(() => {
    const handleShortcut = (e: KeyboardEvent) => {
      switch (e.key) {
        case '3': {
          handleAddRegion('chair');
          break;
        }

        case '0': {
          handleAddRegion('bed');
          break;
        }

        case '1': {
          if (isAdminView && !lockExitIgnore) {
            handleAddRegion('exit');
          }

          break;
        }

        case '6': {
          if (isAdminView && !lockExitIgnore) {
            handleAddRegion('ignore');
          }

          break;
        }

        case 'i': {
          if (isAdminView) {
            handleInvertRegion();
          }

          break;
        }

        case 'x': {
          handleClearSelectedRegion();
          break;
        }

        case 'c': {
          handleClearAllRegions();
          break;
        }

        case 'm': {
          handleCanvasActionChange('move');
          break;
        }

        default:
          break;
      }
    };

    window.addEventListener('keydown', handleShortcut);
    return () => window.removeEventListener('keydown', handleShortcut);
  }, [
    regions,
    isAdminView,
    handleClearAllRegions,
    handleClearSelectedRegion,
    handleInvertRegion,
  ]);

  useEffect(() => {
    const handleSequentialMode = (e: KeyboardEvent) => {
      if (e.key === 'Shift' && ['keydown', 'keyup'].includes(e.type)) {
        setAddSequential(e.type === 'keydown');
      }
    };

    window.addEventListener('keydown', handleSequentialMode);
    window.addEventListener('keyup', handleSequentialMode);

    return () => {
      window.removeEventListener('keydown', handleSequentialMode);
      window.removeEventListener('keyup', handleSequentialMode);
    };
  }, []);

  return (
    <Stack direction={direction}>
      <ZoneUpdateImage
        cursor={cursor}
        roomName={roomName}
        baseId={baseId}
        awsAccountId={awsAccountId}
        app={app}
        isLoading={isGetLoading || imagesIsLoading}
        handleDrawingPointChange={handleDrawingPoint}
        handleMouseMove={handleMouseMove}
        handleMouseUp={handleMouseUp}
        highlightedRegionId={highlightedRegionId}
        regions={regions}
        lastSuccessfulImage={lastSuccessfulImage}
        width={width}
        height={height}
      />
      {showControls ? (
        <CanvasControls
          activeDrawingRegion={regions[drawingRegionIndex]}
          isAdminView={isAdminView}
          canInvertRegion={canInvertRegion}
          handleAddRegion={handleAddRegion}
          regions={sortedRegions}
          handleDrawingRegionChange={handleDrawingRegionChange}
          canvasAction={canvasAction}
          handleCanvasActionChange={handleCanvasActionChange}
          handleClearAllRegions={handleClearAllRegions}
          handleClearSelectedRegion={handleClearSelectedRegion}
          handleCancel={handleCancel}
          handleRegionHover={handleRegionHover}
          handleRegionUpdate={handleUpdate}
          handleLockExitIgnore={handleLockExitIgnore}
          handleInvertRegion={handleInvertRegion}
          lockExitIgnore={lockExitIgnore}
          isLoading={isSavePending}
          allPointsEmpty={allPointsEmpty}
          width={width}
        />
      ) : null}
    </Stack>
  );
};
