import { Stack } from '@mui/material';
import * as yup from 'yup';

import { isValidFallRiskLevel } from '@inspiren-monorepo/shared-types';

import FallRiskPill from '../../../../shared/FallRiskPill';
import InvalidValue from '../../../InvalidValue';
import { RoomFallRiskHistoryModalButton } from '../../../components/RoomFallRiskHistoryModalButton';
import ImportOrgSelect from '../../ImportTable/components/ImportOrgSelect';
import ImportUnitSelect from '../../ImportTable/components/ImportUnitSelect';
import importUniqueRoomValidator from '../validators/importUniqueRoomValidator';

import type { ImportableDataFields } from '../../ImportTable/types/importable';
import type { RoomFieldTypes } from '../types/RoomFieldTypes';

const getRoomsTableFields = (
  filteredOrgs?: string[],
): ImportableDataFields<RoomFieldTypes> => [
  {
    field: 'id',
    label: 'ID',
    width: 100,
    editType: 'text',
    editable: false,
    initialValue: '',
    schema: yup
      .string()
      .required('You must provide an ID')
      .matches(
        /^[\da-z]+$/i,
        'ID can only include letter and number characters',
      ),
    disabledOnImport: (params) => Boolean(params.row.$meta.alreadyExists),
    onImportCellEditValidators: [
      importUniqueRoomValidator('Room already exists'),
    ],
  },
  {
    field: 'displayName',
    label: 'Display name',
    width: 160,
    editType: 'special',
    initialValue: '',
    schema: yup.string().notRequired(),
  },
  {
    field: 'organization',
    label: 'Organization',
    width: 'hidden',
    hideOnAdd: true,
    hideOnEdit: true,
    importType: 'select',
    renderImportCell: (params) => (
      <ImportOrgSelect {...params} filteredOrgs={filteredOrgs} />
    ),
    disabledOnImport: (params) => Boolean(params.row.$meta.alreadyExists),
  },

  {
    field: 'buildingFloorUnit',
    label: 'Building-Floor-Unit',
    width: 190,
    editType: 'special',
    initialValue: '',
    schema: yup.string().required('Building, floor, and unit are required'),
    importType: 'select',
    renderImportCell: (params) => <ImportUnitSelect {...params} />,
    disabledOnImport: (params) => Boolean(params.row.$meta.alreadyExists),
  },
  {
    field: 'fallRiskLevel',
    label: 'Fall Risk Level',
    width: 160,
    editType: 'special',
    initialValue: 'high',
    schema: yup.string().required('Fall risk level is required'),
    renderCell: ({ id, value }) => (
      <Stack
        sx={{ width: '100%' }}
        direction='row'
        justifyContent='space-between'
        alignItems='center'
      >
        {isValidFallRiskLevel(value) ? (
          <FallRiskPill fallRisk={value} size='small' />
        ) : (
          <InvalidValue value={value} />
        )}
        <RoomFallRiskHistoryModalButton roomId={id as string} />
      </Stack>
    ),
    importType: 'select',
    options: ['high', 'low', 'off'],
  },
  {
    field: 'careLevel',
    label: 'Care Level',
    width: 150,
    editType: 'special',
  },
  {
    field: 'hide',
    label: 'Hidden',
    width: 100,
    editType: 'boolean',
    initialValue: false,
    tooltip: 'Hidden rooms will not be visible in mobile or web applications',
    flex: 0,
  },
  {
    field: 'deleted',
    label: 'Archived',
    width: 100,
    editType: 'boolean',
    initialValue: false,
    tooltip:
      'Archived rooms will not be visible in any application - mobile, web, or annotation',
    flex: 0,
  },
];

export default getRoomsTableFields;
