import { useCallback, useMemo } from 'react';
import { Box, Link, Typography, useTheme } from '@mui/material';
import {
  TransferWithinAStation,
  Smartphone,
  Computer,
} from '@mui/icons-material';
import type { GridColDef } from '@mui/x-data-grid';
import * as Sentry from '@sentry/react';
import { format, intervalToDuration } from 'date-fns';
import { format as formatTZ } from 'date-fns-tz';
import { capitalize, isEmpty, isNil } from 'lodash';

import { formatAlertType } from '@inspiren-monorepo/util-formatters';

import ColorSwitch from './ColorSwitch';
import { DataGridBase } from './DataGridBase';
import DisabledTimeLink from './DisabledTimeLink';
import { NotifsSentModal } from './NotifsSentModal';
import TableMenu from './TableMenu';
import TableRowMenu from './TableRowMenu';

import { sendAmpEvent } from '../../../utility/amplitude';
import {
  formatByResolvedByNameAndRoleId,
  makeEventReviewFilename,
} from '../../../utility/helpers/helpers';
import {
  dateTimeFormat,
  dateTimeFormatNoYear,
  getPositionFromDate,
  getTimestampOfNearestImage,
  timeFormat,
} from '../../../utility/helpers/time';
import { useOrganizationRoles } from '../../Admin/hooks/useOrganizationRoles';
import { formatDurationConcise } from '../helpers/formatDurationConcise';
import { gridDurationComparator } from '../helpers/gridDurationComparator';
import { useNotifications } from '../hooks/useNotifications';
import { useTimestamp } from '../hooks/useTimestamp';
import { useEventReviewStore } from '../store/EventReviewStore';

import type { Room } from '../../../../types';
import type { Duration } from 'date-fns';

interface Props {
  showDate?: boolean;
  showRowMenu: boolean;
}

const NotificationsTable = ({ showDate = false, showRowMenu }: Props) => {
  const {
    selectedRoom,
    selectedOrg,
    startDate,
    endDate,
    images,
    showNotifMarks,
    setShowNotifMarks,
    setPosition,
  } = useEventReviewStore();

  const {
    data: notifications,
    isLoading,
    isFetching,
    isError,
  } = useNotifications();

  const handleSetTimeStamp = useCallback(
    (timestamp: Date) => {
      if (isNil(images)) return;
      const position = getPositionFromDate(images, timestamp) || 0;
      setPosition(position);
    },
    [images, setPosition],
  );

  // TODO: Add field types

  const columns: GridColDef[] = useMemo(
    () =>
      [
        {
          field: 'type',
          headerName: 'Type',
          width: 150,
          editable: false,
        },
        {
          field: 'promotedOn',
          headerName: 'Sent at',
          width: 150,
          editable: false,
          valueFormatter: (params) => {
            const formatString = showDate ? dateTimeFormatNoYear : timeFormat;
            return format(params.value, formatString);
          },
          renderCell: (params: any) => {
            if (
              startDate &&
              params.value &&
              (params.value < startDate || isEmpty(images))
            ) {
              return (
                <DisabledTimeLink
                  tooltipText={
                    isEmpty(images) ? '' : 'Outside of selected range'
                  }
                >
                  {params.formattedValue}
                </DisabledTimeLink>
              );
            }

            return (
              <Link
                component='button'
                onClick={() => handleSetTimeStamp(params.value)}
              >
                {params.formattedValue}
              </Link>
            );
          },
        },
        {
          field: 'resolvedAt',
          headerName: 'Resolved at',
          width: 150,
          editable: false,
          valueFormatter: (params) => {
            const formatString = showDate ? dateTimeFormatNoYear : timeFormat;
            return params.value
              ? format(params.value, formatString)
              : 'Superseded';
          },
          renderCell: (params: any) => {
            if (
              (params.value && endDate && params.value > endDate) ||
              isEmpty(images)
            ) {
              return (
                <DisabledTimeLink
                  tooltipText={
                    isEmpty(images) ? '' : 'Outside of selected range'
                  }
                >
                  {params.formattedValue}
                </DisabledTimeLink>
              );
            }

            return (
              <Link
                component='button'
                onClick={() => handleSetTimeStamp(params.value)}
              >
                {params.formattedValue}
              </Link>
            );
          },
        },
        {
          field: 'elapsed',
          headerName: 'Duration',
          width: 150,
          editable: false,
          valueFormatter: (params) => {
            const duration = params.value as Duration | null;
            return isNil(duration) ? '' : formatDurationConcise(duration);
          },
          sortComparator: gridDurationComparator,
        },
        {
          field: 'resolvedByName',
          headerName: 'Resolved by',
          width: 250,
          editable: false,
        },
        {
          field: 'source',
          headerName: 'Response via',
          editable: false,
          align: 'center',
          width: 150,
          renderCell: (params: any) => {
            if (!params.value) return null;
            if (params.value === 'beacon') return <TransferWithinAStation />;
            if (params.value === 'web') return <Computer />;
            if (params.value === 'mobile') return <Smartphone />;
            return null;
          },
        },
        {
          field: 'received',
          headerName: 'Received By',
          width: 175,
          editable: false,
          filterable: false,
          sortable: false,
          renderCell: ({ row }) => (
            <NotifsSentModal roomId={row.room} notificationId={row.id} />
          ),
        },
        {
          field: 'fr',
          headerName: 'Fall Risk',
          width: 150,
        },
        {
          field: 'id',
          headerName: 'Spotlight',
          sortable: false,
          filterable: false,
          width: 100,
          hide: !showRowMenu,
          renderCell: (params: any) => (
            <TableRowMenu
              id={params.value}
              eventStart={params.row.promotedOn}
              eventEnd={params.row.resolvedAt}
            />
          ),
        },
      ] as GridColDef[],
    [startDate, endDate, showDate, images, handleSetTimeStamp, showRowMenu],
  );

  const { roleMap } = useOrganizationRoles(selectedOrg?.id);

  const timestamp = useTimestamp();

  const notifsExist = notifications && notifications.length > 0;
  const showToggle = notifsExist && !isNil(images) && images.length > 0;

  const timezone = formatTZ(new Date(), 'zzz');

  const loading = useMemo(
    () => isLoading && isFetching,
    [isLoading, isFetching],
  );

  const csvData = useMemo(
    () =>
      notifications?.map((n) => {
        const promoted = new Date(n.promotedOn);
        const resolved = isNil(n.resolvedAt) ? null : new Date(n.resolvedAt);

        return {
          Type: n.type ? formatAlertType(n.type) : '',
          [`Sent at (${timezone})`]:
            promoted && format(promoted, dateTimeFormat),
          [`Resolved at (${timezone})`]: resolved
            ? format(resolved, dateTimeFormat)
            : 'Superseded',
          Duration:
            promoted &&
            resolved &&
            formatDurationConcise(
              intervalToDuration({ start: promoted, end: resolved }),
            ),
          'Resolved by': formatByResolvedByNameAndRoleId(
            roleMap,
            n.resolvedByName,
            n.resolvedByRoleId,
          ),
          'Response via': n.source || '',
          'Fall sensitivity': n.fr && capitalize(n.fr),
        };
      }) || [],
    [notifications, timezone, roleMap],
  );

  const activeRows = useMemo(
    () =>
      notifications?.map((notif, index) => {
        const nearestImageToPromoted =
          (notif.promotedOn &&
            getTimestampOfNearestImage(images, new Date(notif.promotedOn))) ||
          null;

        const nearestImageToResolved =
          (notif.resolvedAt &&
            getTimestampOfNearestImage(images, new Date(notif.resolvedAt))) ||
          null;

        let active = false;

        if (
          timestamp &&
          nearestImageToPromoted &&
          timestamp >= nearestImageToPromoted
        ) {
          if (notif.resolvedAt && nearestImageToResolved) {
            active = timestamp <= nearestImageToResolved;
          } else if (notifications[index + 1]) {
            const nearestImageToNext = getTimestampOfNearestImage(
              images,
              new Date(notifications[index + 1].promotedOn),
            );

            active = timestamp < nearestImageToNext!;
          } else {
            active = true;
          }
        }

        if (active) return notif.promotedOn;
        return null;
      }) || [],
    [notifications, images, timestamp],
  );

  const notifsTableData = useMemo(
    () =>
      notifications?.map((notif) => {
        const promoted = new Date(notif.promotedOn);

        const resolved = isNil(notif.resolvedAt)
          ? null
          : new Date(notif.resolvedAt);

        return {
          id: notif.id,
          room: notif.room,
          promotedOn: promoted,
          resolvedAt: resolved,
          elapsed:
            promoted &&
            resolved &&
            intervalToDuration({ start: promoted, end: resolved }),
          resolvedByName: formatByResolvedByNameAndRoleId(
            roleMap,
            notif.resolvedByName,
            notif.resolvedByRoleId,
          ),
          type: notif.type ? formatAlertType(notif.type) : '',
          fr: notif.fr && capitalize(notif.fr),
          source: notif?.source || null,
        };
      }),
    [notifications, roleMap],
  );

  const theme = useTheme();
  const backgroundColor = theme.palette.notificationEvent.light;

  return (
    <>
      <Box
        sx={{ display: 'flex', alignItems: 'center' }}
        data-testid='eventreview-notificationstable-header'
      >
        <Typography variant='h5' component='h3' p={1.5}>
          Notifications
        </Typography>
        <ColorSwitch
          checked={showToggle ? showNotifMarks : false}
          disabled={!showToggle}
          onChange={() => {
            setShowNotifMarks(!showNotifMarks);
            sendAmpEvent('Toggled Notif Events', { toggle: showNotifMarks });
          }}
          inputProps={{ 'aria-label': 'controlled' }}
          size='small'
          customColor={(theme) => theme.palette.notificationEvent.main}
        />
        {notifsExist && (
          <TableMenu
            id='notifs'
            csvData={csvData}
            filename={
              selectedRoom &&
              startDate &&
              endDate &&
              makeEventReviewFilename(
                'Notifications',
                selectedRoom as Room,
                startDate,
                endDate,
              )
            }
          />
        )}
      </Box>
      <DataGridBase
        noRowsLabel='No notifications in range'
        rows={notifsTableData || []}
        columns={columns}
        loading={loading}
        error={isError}
        backgroundColor={backgroundColor}
        getRowClassName={(params) => {
          const date = new Date(params.row.promotedOn);

          if (activeRows.includes(date.toISOString())) {
            return 'row-active--true';
          }

          return '';
        }}
      />
    </>
  );
};

export default Sentry.withProfiler(NotificationsTable);
