import { Api } from '../../../utility/client';

import type { AdminOrganizationSettings } from '../../../../types';

export const getAdminOrgSettings = async (orgId: string) => {
  const res = await Api.get<AdminOrganizationSettings>(
    `/admin/v1/orgs/${orgId}/settings`,
  );

  return res.data;
};
