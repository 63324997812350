import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { isNil, keyBy } from 'lodash';
import toast from 'react-hot-toast';

import { ControlledError } from '@inspiren-monorepo/shared-types';

import { SelectCareLevel } from './SelectCareLevel';
import getRoomsTableFields from './constants/getRoomsTableFields';
import useRoomsUpsert from './hooks/useRoomsUpsert';

import { useCurrentUser } from '../../../../HOC/CurrentUserContextProvider';
import { useIsAdmin } from '../../../../hooks/useIsAdmin';
import {
  extractBuildingFloorUnitFromRoomId,
  extractOrgFromRoomId,
  extractRoomNumberFromRoomId,
  makeRoomId,
} from '../../../../utility/helpers/id';
import BulkImportLink from '../../components/BulkImportLink';
import OrgHeader from '../../components/OrgHeader';
import OrgSelector from '../../components/OrgSelector';
import { getOrgs } from '../../data-access/getOrgs';
import { useOrgRooms } from '../../hooks/useOrgRooms';
import DisplayName from '../../modals/special/DisplayName';
import SelectBuildingFloorUnit from '../../modals/special/SelectBuildingFloorUnit';
import SelectRoomFallRisk from '../../modals/special/SelectRoomFallRisk';
import { useCareLevels } from '../CareLevelsTable/hooks/useCareLevels';
import TableBase from '../TableBase';

import type { RoomFieldTypes } from './types/RoomFieldTypes';
import type { Organization } from '../../../../../types';
import type {
  OnSubmitFormModal,
  RenderFormModal,
} from '../../modals/FormModalBase';

const roomsTableFields = getRoomsTableFields();

const RoomsTable = () => {
  const { user } = useCurrentUser();
  const { isAdmin } = useIsAdmin();
  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);

  const orgId = useMemo(
    () => (isAdmin ? selectedOrg?.id : user?.org),
    [isAdmin, selectedOrg?.id, user?.org],
  );

  const {
    isLoading: orgsLoading,
    data: orgs,
    isError: orgsError,
  } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const { isLoading: careLevelsLoading, data: careLevels } = useCareLevels(
    selectedOrg?.id,
  );

  const careLevelsById = useMemo(
    () => keyBy(careLevels || [], 'id'),
    [careLevels],
  );

  const {
    isFetching: roomsFetching,
    isError: roomsError,
    data: rooms,
  } = useOrgRooms({ orgId, includeDeleted: true });

  const { handleAddSubmit, handleEditSubmit } = useRoomsUpsert();

  const handleOrgChange = useCallback(
    (_e: React.ChangeEvent<object>, newValue: Organization | null) => {
      setSelectedOrg(newValue);
    },
    [],
  );

  const data = useMemo(
    () =>
      (rooms || []).map(
        ({ mainId, fallRiskLevel, careLevelId, name, hide, deleted }) => ({
          id: extractRoomNumberFromRoomId(mainId) || '',
          organization: extractOrgFromRoomId(mainId) || '',
          buildingFloorUnit: extractBuildingFloorUnitFromRoomId(mainId) || '',
          fallRiskLevel: fallRiskLevel === 'medium' ? 'high' : fallRiskLevel,
          careLevel: careLevelId
            ? careLevelsById[careLevelId]?.displayName || ''
            : '',
          displayName: name,
          hide,
          deleted,
        }),
      ),
    [rooms, careLevelsById],
  );

  const onEditSubmit: OnSubmitFormModal<RoomFieldTypes> = useCallback(
    async (item) => {
      try {
        await handleEditSubmit({
          ...item,
          organization: orgId!,
        });

        toast.success(`Successfully updated room ${item.id}`);
      } catch (error) {
        const message =
          error instanceof ControlledError
            ? error.message
            : `Error updating room${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [handleEditSubmit, orgId],
  );

  const onAddSubmit: OnSubmitFormModal<RoomFieldTypes> = useCallback(
    async (item) => {
      try {
        await handleAddSubmit({
          ...item,
          organization: orgId!,
        });

        toast.success(`Successfully added room ${item.id}`);
      } catch (error) {
        const message =
          error instanceof ControlledError
            ? error.message
            : `Error adding room${error ? `: ${error}` : ''}`;

        toast.error(message);
      }
    },
    [handleAddSubmit, orgId],
  );

  const renderModal: RenderFormModal<RoomFieldTypes> = useCallback(
    ({ defaultComponents, control }) => (
      <>
        <OrgHeader displayName={selectedOrg?.name} id={selectedOrg?.id} />
        {defaultComponents.id}
        <DisplayName
          field='displayName'
          label='Display Name'
          control={control}
        />
        <SelectBuildingFloorUnit control={control} org={orgId} />
        {/* TODO: Use switch UI for fall risk similar to room modal */}
        <SelectRoomFallRisk control={control} orgId={orgId} />
        <SelectCareLevel control={control} org={orgId} />
        {defaultComponents.hide}
        {defaultComponents.deleted}
      </>
    ),
    [selectedOrg, orgId, isAdmin],
  );

  return (
    <Stack spacing={2}>
      {isAdmin && (
        <OrgSelector
          orgs={orgs ?? []}
          loading={orgsLoading}
          value={selectedOrg}
          onChange={handleOrgChange}
        />
      )}
      <TableBase<RoomFieldTypes>
        itemName='Room'
        fields={roomsTableFields}
        data={data}
        loading={roomsFetching || careLevelsLoading}
        renderModal={renderModal}
        modalLoading={orgsLoading}
        modalError={orgsError}
        error={roomsError}
        getRowId={(row) =>
          makeRoomId(row.organization, row.buildingFloorUnit, row.id) || ''
        }
        onEditSubmit={onEditSubmit}
        onAddSubmit={onAddSubmit}
        customNoRowsText={isNil(orgId) ? 'No organization selected' : undefined}
        disableAddButton={isNil(orgId)}
        extraToolbarButtons={
          isAdmin && (
            <BulkImportLink
              disabled={!orgId}
              itemName='rooms'
              urlSuffix={orgId}
            />
          )
        }
      />
    </Stack>
  );
};

export default RoomsTable;
