import ControlledCheckbox from '../ControlledCheckbox';
import ControlledDropdown from '../ControlledDropdown';
import ControlledMobilePhoneField from '../ControlledMobilePhoneField';
import ControlledPasswordInput from '../ControlledPasswordInput';
import ControlledTextField from '../ControlledTextField';
import ControlledTextFieldGroup from '../ControlledTextFieldGroup';
import EditOrgUnitRoom from '../special/EditOrgUnitRoom';
import SelectBuilding from '../special/SelectBuilding';
import SelectOrg from '../special/SelectOrg';
import SelectOrgFloor from '../special/SelectOrgFloor';
import SelectPrivOpts from '../special/SelectPrivOpts';
import SelectVirtualScenario from '../special/SelectVirtualScenario';

import type { DataField } from '../../types/DataFields';
import type { Control, UseFormSetValue } from 'react-hook-form';

export const getDefaultComponentFromFieldData = <FieldTypes,>(
  dataField: DataField<FieldTypes>,
  control: Control,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setValue: UseFormSetValue<any>,
  itemName: string,
  type: 'add' | 'edit',
) => {
  const {
    field,
    label,
    options,
    editType,
    editable = true,
    disabled = false,
    hideOnAdd = false,
    hideOnEdit = false,
    tooltip,
    properties,
  } = dataField;

  if (type === 'add' && hideOnAdd) return null;
  if (type === 'edit' && hideOnEdit) return null;

  const isDisabled = disabled || (editable === false && type === 'edit');

  switch (editType) {
    case 'mobile-phone':
      return (
        <ControlledMobilePhoneField
          key={field}
          id={field}
          label={label}
          control={control}
          disabled={isDisabled}
          tooltip={tooltip}
        />
      );
    case 'text-group':
      return (
        <ControlledTextFieldGroup
          key={field}
          id={field}
          label={label}
          control={control}
          setValue={setValue}
          disabled={isDisabled}
          tooltip={tooltip}
          properties={properties}
        />
      );
    case 'text':
      return (
        <ControlledTextField
          key={field}
          id={field}
          label={label}
          control={control}
          disabled={isDisabled}
          tooltip={tooltip}
        />
      );
    case 'password':
      return (
        <ControlledPasswordInput
          key={field}
          id={field}
          label={label}
          control={control}
        />
      );
    case 'select':
      return (
        <ControlledDropdown
          key={field}
          id={field}
          label={label}
          items={options || []}
          control={control}
          disabled={isDisabled}
        />
      );
    case 'boolean':
      return (
        <ControlledCheckbox
          key={field}
          id={field}
          label={label}
          control={control}
          tooltip={tooltip}
          // disabled={isDisabled}
        />
      );

    case 'special':
      // Inject special edit components
      // TODO: Move these into their respective table files using the renderModal prop

      if (itemName === 'Basestation' && field === 'organization') {
        return (
          <EditOrgUnitRoom key={field} control={control} setValue={setValue} />
        );
      }

      if (itemName === 'Basestation' && field === 'virtual') {
        return <SelectVirtualScenario key={field} control={control} />;
      }

      if (itemName === 'Building' && field === 'org') {
        return <SelectOrg key={field} type={type} control={control} />;
      }

      if (itemName === 'Floor' && field === 'building') {
        return <SelectBuilding key={field} type={type} control={control} />;
      }

      if (itemName === 'Unit' && field === 'org') {
        return (
          <SelectOrgFloor
            key={field}
            type={type}
            setValue={setValue}
            control={control}
          />
        );
      }

      if (itemName === 'Unit' && field === 'privOpts') {
        return <SelectPrivOpts key={field} control={control} />;
      }

      return null;
    default:
      return null;
  }
};
