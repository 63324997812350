import { useCallback } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import * as yup from 'yup';

import TableBase from './TableBase';

import { postFloor } from '../../../utility';
import { getBuildings } from '../data-access/getBuildings';
import { getFloors } from '../data-access/getFloors';

import type { OnSubmitFormModal } from '../modals/FormModalBase';
import type { DataFields } from '../types/DataFields';

export type FieldTypes = {
  building: string;
  floorNumber: string;
};

const fields: DataFields<FieldTypes> = [
  {
    field: 'building',
    label: 'Building',
    width: 200,
    editType: 'special',
    editable: false,
    schema: yup.string().required('Building is required'),
  },
  {
    field: 'floorNumber',
    label: 'Floor Number',
    width: 160,
    editType: 'text',
    editable: false,
    schema: yup
      .number()
      .positive()
      .integer()
      .required('Floor number is required'),
  },
];

const FloorsTable = () => {
  const queryClient = useQueryClient();

  const { isLoading: buildingsLoading, isError: buildingsError } = useQuery({
    queryKey: ['buildings'],
    queryFn: getBuildings,
  });

  const {
    isLoading: floorsLoading,
    isError: floorsError,
    data: floors,
  } = useQuery({
    queryKey: ['floors'],
    queryFn: getFloors,
  });

  const handleAddSubmit: OnSubmitFormModal<FieldTypes> = useCallback(
    async ({ building, floorNumber }) => {
      try {
        await postFloor({ building, floorNumber: Number(floorNumber) });

        await queryClient.invalidateQueries({
          queryKey: ['floors'],
        });

        toast.success(`Successfully added floor ${floorNumber} to ${building}`);
      } catch (error) {
        toast.error(`Error adding floor${error ? `: ${error}` : ''}`);
      }
    },
    [],
  );

  return (
    <TableBase<FieldTypes>
      itemName='Floor'
      fields={fields}
      data={floors}
      getRowId={(row) => `${row.building}-${row.floorNumber}`}
      loading={floorsLoading}
      error={floorsError}
      modalLoading={buildingsLoading}
      modalError={buildingsError}
      onAddSubmit={handleAddSubmit}
      disableEditing
    />
  );
};

export default FloorsTable;
