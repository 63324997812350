import type React from 'react';
import { sortBy } from 'lodash';

import LoadingAutocomplete from '../../shared/LoadingAutocomplete';

import type { Organization } from '../../../../types';

interface Props {
  orgs: Organization[];
  loading: boolean;
  value: Organization | null;
  onChange: (
    event: React.ChangeEvent<object>,
    value: Organization | null,
  ) => void;
}

const OrgSelector = ({ orgs, loading, value, onChange }: Props) => (
  <LoadingAutocomplete
    label='Organization'
    loading={loading}
    size='small'
    id='org'
    options={sortBy(orgs, 'name')}
    value={value}
    getOptionLabel={(option) => `${option.name} (${option.id})`}
    isOptionEqualToValue={(o, v) => o.id === v.id}
    onChange={onChange}
    sx={{ maxWidth: 360 }}
  />
);

export default OrgSelector;
