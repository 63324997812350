import type { PrefetchImage } from '../hooks';

export const prefetchImage: PrefetchImage = (url, onLoad, onError) => {
  const img = new Image();

  img.onload = () => {
    onLoad?.();
  };

  img.onerror = () => {
    onError?.();
  };

  img.onabort = () => {
    onError?.();
  };

  img.src = url;
};
