import { sortBy } from 'lodash';

import type { Unit } from '../../../types';

export function sortUnitDropdown(units: Unit[] | undefined): Unit[] {
  if (!units) {
    return [];
  }

  return sortBy(units, ['buildingDisplayName', 'displayName', 'name']);
}
