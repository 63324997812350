import { useCallback, useMemo, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Toolbar,
} from '@mui/material';
import { Close, ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import * as Sentry from '@sentry/react';

import { type AlertType, UserEventType } from '@inspiren-monorepo/shared-types';
import { formatAlertType } from '@inspiren-monorepo/util-formatters';

import RoomBottomBar from './RoomCard/RoomBottomBar';
import RoomImage from './RoomImage';
import RoomTopBar from './RoomTopBar';
import { ZoneUpdateWrapper } from './ZoneUpdate/ZoneUpdateWrapper';
import { ArrowIconButton } from './components/ArrowIconButton';
import { useUnitForRoom } from './hooks/useUnitForRoom';

import { useCurrentUser } from '../../HOC/CurrentUserContextProvider';
import { getAlertAnimationSxProps } from '../../styles/alertAnimation';
import { useTrackUserEvent } from '../../utility/analytics';
import { extractRoomNumberFromRoomId } from '../../utility/helpers/id';

import type { TeleRoom } from '../../../types';

interface Props {
  imgSrc?: string;
  room: TeleRoom;
  onCloseClick: () => void;
  setTooltip: (state: boolean) => void;
  onPrivacyModeChange: () => void;
  resolve: () => void;
  resolveNotifLoading: boolean;
  disabled?: boolean;
  imageLoading?: boolean;
  offline: boolean;
  alert: AlertType | null;
  privacy: boolean;
  playingAudibleMessage: boolean;
  onNextClick?: () => void;
  onPrevClick?: () => void;
}

const RoomModal = ({
  imgSrc,
  room,
  onCloseClick,
  setTooltip,
  onPrivacyModeChange,
  resolve,
  disabled,
  imageLoading = false,
  offline = false,
  privacy = false,
  alert,
  playingAudibleMessage,
  resolveNotifLoading,
  onNextClick,
  onPrevClick,
}: Props) => {
  const { trackEvent } = useTrackUserEvent();
  const { user } = useCurrentUser();
  const unit = useUnitForRoom(room.mainId);

  const [showZoneUpdate, setShowZoneUpdate] = useState(false);

  const showMenu = useMemo(
    () =>
      user?.ability?.can('view', 'global.disable-augi') ||
      user?.ability?.can('view', 'global.fall-risk-off') ||
      user?.ability?.can('view', 'global.fall-risk-high-low'),
    [user],
  );

  const alertAnimation = useMemo(
    () => (alert && getAlertAnimationSxProps(alert, 'backgroundColor')) || {},
    [alert],
  );

  const onToggleZoneUpdate = useCallback(() => {
    setShowZoneUpdate((szu) => !szu);
  }, [setShowZoneUpdate]);

  const closeModal = useCallback(() => {
    onCloseClick();

    trackEvent({
      type: alert
        ? UserEventType.AlertLiveViewClosed
        : UserEventType.ExpandedLiveViewClosed,
      room: room.mainId,
    });
  }, [onCloseClick, trackEvent, room, showZoneUpdate, alert]);

  const onDialogClose = useCallback(
    (_evt: object, reason: string) => {
      if (reason === 'backdropClick' && showZoneUpdate) {
        return;
      }

      closeModal();
    },
    [onCloseClick, trackEvent, room, showZoneUpdate],
  );

  const commonArrowWrapperSx = {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  };

  return (
    <Dialog open onClose={onDialogClose} maxWidth='lg'>
      {!showZoneUpdate && (
        <>
          <Stack
            direction='row'
            width='100%'
            alignItems='center'
            sx={alertAnimation}
          >
            <DialogTitle sx={{ height: 54 }}>
              {alert
                ? `${formatAlertType(alert, unit?.bathroomAlertThreshold)} alert`
                : ''}
            </DialogTitle>

            <IconButton
              aria-label='close'
              onClick={closeModal}
              sx={{ ml: 'auto', mr: 1, height: 'fit-content' }}
            >
              <Close />
            </IconButton>
          </Stack>

          <DialogContent sx={{ overflow: 'hidden', p: 0, display: 'flex' }}>
            <RoomTopBar
              room={room}
              playingAudibleMessage={playingAudibleMessage}
              disabled={disabled}
              offline={offline}
              privacy={privacy}
              onPrivacyModeChange={onPrivacyModeChange}
              onToggleZoneUpdate={onToggleZoneUpdate}
              size='large'
              sx={{ pt: 1.5, pl: 2, pr: 0.5 }}
              showMenu={showMenu}
            />

            <RoomBottomBar
              room={room}
              size='large'
              resolve={resolve}
              resolveNotifLoading={resolveNotifLoading}
              setTooltip={setTooltip}
              alert={alert}
              sx={{ pb: 1.5, px: 2 }}
              offline={offline}
              disabled={disabled}
              showFallRisk
              fallRiskPillTestID='expandedroom-fallriskpill'
            />

            <RoomImage
              src={imgSrc}
              enableZoom
              disabled={disabled}
              privacy={privacy}
              privEndsAt={room.priv}
              loading={imageLoading}
              offline={offline}
              testID='expandedroom-image'
              keepAlive={room.keepAlive}
            />

            {onPrevClick && (
              <Toolbar
                sx={{
                  ...commonArrowWrapperSx,
                  left: 0,
                }}
              >
                <ArrowIconButton
                  onClick={onPrevClick}
                  icon={<ArrowBackIosNew fontSize='large' />}
                />
              </Toolbar>
            )}

            {onNextClick && (
              <Toolbar
                sx={{
                  ...commonArrowWrapperSx,
                  right: 0,
                }}
              >
                <ArrowIconButton
                  onClick={onNextClick}
                  icon={<ArrowForwardIos fontSize='large' />}
                />
              </Toolbar>
            )}
          </DialogContent>
        </>
      )}

      {showZoneUpdate && (
        <ZoneUpdateWrapper
          imagesIsLoading={imageLoading}
          lastSuccessfulImage={imgSrc}
          roomId={room.mainId}
          roomName={room.name || extractRoomNumberFromRoomId(room.mainId) || ''}
          toggleZoneUpdate={onToggleZoneUpdate}
        />
      )}
    </Dialog>
  );
};

export default Sentry.withProfiler(RoomModal);
