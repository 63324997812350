import type React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { Tooltip } from '@mui/material';
import { Save, Cancel, Edit, Delete } from '@mui/icons-material';
import type {
  GridColumns,
  GridEventListener,
  GridRowId,
  GridRowParams,
  GridValidRowModel,
  MuiEvent,
} from '@mui/x-data-grid';
import {
  GridActionsCellItem,
  GridColumnHeaderTitle,
  GridRowModes,
  useGridApiRef,
} from '@mui/x-data-grid';
import PromisePool from '@supercharge/promise-pool';
import { isEmpty } from 'lodash';
import { CheckmarkIcon, ErrorIcon, LoaderIcon } from 'react-hot-toast';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';

import { useBeforeUnload } from '@inspiren-monorepo/shared-react';

import useGridRowModes from './useGridRowModes';

import { getAxiosErrorMessage } from '../../../../../utility/error-handler';
import { buildGridColumn } from '../helpers/buildGridColumn';

import type { Importable, ImportableDataFields } from '../types/importable';
import type { UploadFn } from '../types/uploadFn';

enum UploadStatus {
  success = 4,
  uploading = 2,
  error = 1,
  none = 0,
}

type UploadStatusDetails = {
  status: UploadStatus;
  apiError?: string;
};

export type ImportTableHookProps<TFieldTypes extends GridValidRowModel> = {
  createDefaultRow: () => TFieldTypes;
  mapCsvToFields: (csvRows: string[][]) => Promise<Importable<TFieldTypes>[]>;
  fields: ImportableDataFields<TFieldTypes>;
  upload: UploadFn<TFieldTypes>;

  onRowUpdate?: (
    row: Importable<TFieldTypes>,
  ) => Importable<TFieldTypes> | Promise<Importable<TFieldTypes>>;

  bulkUpdateSupport?: boolean;
};

export const useImportTable = <TFieldTypes extends GridValidRowModel>({
  fields,
  createDefaultRow,
  mapCsvToFields,
  upload,
  onRowUpdate,
  bulkUpdateSupport,
}: ImportTableHookProps<TFieldTypes>) => {
  const apiRef = useGridApiRef();

  const { rowModesModel, setRowModesModel, updateMode, saveAll, canSaveAll } =
    useGridRowModes();

  const [rows, setRows] = useState<Importable<TFieldTypes>[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [uploadStatusMap, setUploadStatusMap] = useState<{
    [key: string]: UploadStatusDetails;
  }>({});

  const isDirty = useMemo(() => rows.length > 0, [rows.length]);

  useBeforeUnload(isDirty);

  const updateUploadStatus = useCallback(
    (uniqueId: string, status: UploadStatus, apiError?: string) => {
      setUploadStatusMap((old) => ({
        ...old,
        [uniqueId]: { status, apiError },
      }));
    },
    [setUploadStatusMap],
  );

  const importBlocked = useMemo(
    () =>
      loading ||
      Object.values(rowModesModel).some((r) => r.mode === GridRowModes.Edit) ||
      rows.length === 0 ||
      rows.some((r) => Boolean(r.$meta.validationError)) ||
      Object.values(uploadStatusMap).some(
        (e) => e.status === UploadStatus.uploading,
      ),
    [loading, rows, uploadStatusMap, rowModesModel],
  );

  const handleEditClick = useCallback(
    (uniqueId: GridRowId) => () => {
      updateMode(uniqueId, { mode: GridRowModes.Edit });
    },
    [updateMode],
  );

  const handleSaveClick = useCallback(
    (uniqueId: GridRowId) => () => {
      updateMode(uniqueId, { mode: GridRowModes.View });
    },
    [updateMode],
  );

  const handleDeleteClick = useCallback(
    (uniqueId: GridRowId) => () => {
      setRows((old) => old.filter((row) => row.$meta.uniqueId !== uniqueId));
    },
    [],
  );

  const handleCancelClick = useCallback(
    (uniqueId: GridRowId) => () => {
      updateMode(uniqueId, {
        mode: GridRowModes.View,
        ignoreModifications: true,
      });

      setRows((old) =>
        old.filter(
          (row) => row.$meta.uniqueId !== uniqueId || !row.$meta.isNew,
        ),
      );
    },
    [updateMode],
  );

  const handleRowEditStart = useCallback(
    (params: GridRowParams, event: MuiEvent<React.SyntheticEvent>) => {
      // eslint-disable-next-line no-param-reassign
      event.defaultMuiPrevented = true;
    },
    [],
  );

  const handleRowEditStop: GridEventListener<'rowEditStop'> = useCallback(
    (params, event) => {
      // eslint-disable-next-line no-param-reassign
      event.defaultMuiPrevented = true;
    },
    [],
  );

  const objectSchema = useMemo(
    () =>
      yup.object(
        Object.fromEntries(
          fields
            .filter((cur) => !isEmpty(cur.importSchema ?? cur.schema))
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            .map((cur) => [cur.field, cur.importSchema ?? cur.schema!]),
        ),
      ),
    [fields],
  );

  const validate = (item: Importable<TFieldTypes>) => {
    try {
      objectSchema.validateSync(item);
    } catch (error) {
      if (error instanceof Error) {
        return error.message;
      }

      return 'Unknown error';
    }

    for (const field of fields) {
      if (
        field.onImportCellEditValidators &&
        field.onImportCellEditValidators?.length > 0
      ) {
        for (const validator of field.onImportCellEditValidators) {
          const error = validator(item.$meta.uniqueId, item, field.field, rows);

          if (error) {
            return error;
          }
        }
      }
    }

    return undefined;
  };

  const processRowUpdate = async (newRow: Importable<TFieldTypes>) => {
    let row: Importable<TFieldTypes> = newRow;

    if (onRowUpdate) {
      row = await onRowUpdate(newRow);
    }

    const validationError = validate(row);

    const updatedRow: Importable<TFieldTypes> = {
      ...row,
      $meta: {
        ...row.$meta,
        isNew: validationError ? row.$meta.isNew : false,
        validationError,
      },
    };

    setRows((prev) =>
      prev.map((p) =>
        p.$meta.uniqueId === row.$meta.uniqueId ? updatedRow : p,
      ),
    );

    if (validationError) {
      throw new Error(validationError);
    }

    updateUploadStatus(row.$meta.uniqueId, UploadStatus.none);

    return updatedRow;
  };

  const onAddRow = useCallback(() => {
    const uniqueId = uuidv4();

    setRows((oldRows) => [
      ...oldRows,
      {
        ...createDefaultRow(),
        $meta: {
          uniqueId,
          isNew: true,
          alreadyExists: false,
        },
      } as Importable<TFieldTypes>,
    ]);

    setRowModesModel((oldModel) => ({
      ...oldModel,
      [uniqueId]: { mode: GridRowModes.Edit, fieldToFocus: fields[0]?.field },
    }));
  }, [createDefaultRow]);

  const onCsvImportAccepted = useCallback(
    async (csvRows: string[][]): Promise<void> => {
      try {
        setLoading(true);

        let objects = await mapCsvToFields(csvRows);

        objects = objects.map((obj) => {
          obj.$meta.validationError = validate(obj);
          return obj;
        });

        setRows((oldRows) => [...oldRows, ...objects]);

        setRowModesModel((oldModel) =>
          objects.reduce(
            (acc, cur) => ({
              ...acc,
              [cur.$meta.uniqueId]: {
                mode: GridRowModes.Edit,
                fieldToFocus: fields[0]?.field,
              },
            }),
            oldModel,
          ),
        );
      } finally {
        setLoading(false);
      }
    },
    [mapCsvToFields],
  );

  const fieldColumns = useMemo(
    () => fields.map((field) => buildGridColumn(field, rows)),
    [fields, rows],
  );

  const importColumns: GridColumns<Importable<TFieldTypes>> = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      renderHeader: (params) => (
        <>
          <Tooltip title='Save all rows and move them into view mode. You still have to click "Import Data" button to send it into API'>
            <span>
              <GridActionsCellItem
                icon={<Save />}
                label='Save all'
                onClick={saveAll}
                disabled={!canSaveAll}
              />
            </span>
          </Tooltip>
          <GridColumnHeaderTitle
            {...params}
            label='Actions'
            columnWidth={100}
          />
        </>
      ),
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <Tooltip title='Save rows and move it into view mode. You still have to click "Import Data" button to send it into API'>
              <GridActionsCellItem
                icon={<Save />}
                label='Save'
                onClick={handleSaveClick(id)}
              />
            </Tooltip>,
            <Tooltip title='Cancels edit and rollbacks row data'>
              <GridActionsCellItem
                icon={<Cancel />}
                label='Cancel'
                className='textPrimary'
                onClick={handleCancelClick(id)}
                color='inherit'
              />
            </Tooltip>,
          ];
        }

        return [
          <Tooltip title='Edit row'>
            <GridActionsCellItem
              icon={<Edit />}
              label='Edit'
              className='textPrimary'
              onClick={handleEditClick(id)}
              color='inherit'
              disabled={
                !bulkUpdateSupport &&
                uploadStatusMap[id]?.status === UploadStatus.success
              }
            />
          </Tooltip>,
          <Tooltip title='Delete row'>
            <GridActionsCellItem
              icon={<Delete />}
              label='Delete'
              onClick={handleDeleteClick(id)}
              color='inherit'
            />
          </Tooltip>,
        ];
      },
    },
    {
      field: 'mode',
      disableExport: true,
      headerName: 'Mode',
      width: 100,
      cellClassName: 'actions',
      valueGetter: (params) =>
        params.row.$meta.alreadyExists ? 'update' : 'add',
    },
    {
      field: 'uploadStatus',
      disableExport: true,
      headerName: 'Status',
      width: 100,
      cellClassName: 'actions',
      valueGetter: (params) => uploadStatusMap[params.id] ?? 0,
      renderCell: ({ id }) => {
        const { status, apiError } = uploadStatusMap[id] || {};

        if (status === UploadStatus.uploading) {
          return <GridActionsCellItem icon={<LoaderIcon />} label='Uploaded' />;
        }

        if (status === UploadStatus.error) {
          return (
            <Tooltip title={apiError || 'Unknown error'}>
              <GridActionsCellItem icon={<ErrorIcon />} label='Error' />
            </Tooltip>
          );
        }

        if (status === UploadStatus.success) {
          return (
            <GridActionsCellItem icon={<CheckmarkIcon />} label='Success' />
          );
        }

        return <div />;
      },
    },
  ];

  const columns: GridColumns<Importable<TFieldTypes>> = [
    ...fieldColumns,
    ...importColumns,
  ];

  const onImport = async () => {
    setLoading(true);

    try {
      const rowsToUpload = rows.filter(
        (e) =>
          uploadStatusMap[e.$meta.uniqueId]?.status !== UploadStatus.success,
      );

      await PromisePool.withConcurrency(10)
        .for(rowsToUpload)
        .process((item) => {
          const {
            $meta: { uniqueId },
          } = item;

          updateUploadStatus(uniqueId, UploadStatus.uploading);

          const validationError = validate(item);

          if (validationError) {
            updateUploadStatus(
              uniqueId,
              UploadStatus.error,
              `Validation error: ${validationError}`,
            );

            return;
          }

          upload(item)
            .then(() => {
              updateUploadStatus(uniqueId, UploadStatus.success);
            })
            .catch((error) => {
              const errorMessage =
                getAxiosErrorMessage(error) ?? error.message ?? 'Unknown error';

              updateUploadStatus(uniqueId, UploadStatus.error, errorMessage);
            });
        });
    } finally {
      setLoading(false);
    }
  };

  return {
    apiRef,
    columns,
    rows,
    setRows,
    rowModesModel,
    setRowModesModel,
    handleEditClick,
    handleRowEditStart,
    handleRowEditStop,
    processRowUpdate,
    importBlocked,
    onAddRow,
    onCsvImportAccepted,
    onImport,
    loading,
    saveAll,
    canSaveAll,
  };
};
