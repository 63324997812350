import type { RegionConfig } from '@inspiren-monorepo/hilq/api-contracts';

import { Api } from '../../../utility/client';

interface Props {
  roomId: string;
  regions: RegionConfig[];
}

export const updateRoomRegions = async ({
  roomId,
  regions,
}: Props): Promise<RegionConfig[]> => {
  try {
    const res = await Api.put(`/telesitting/v1/rooms/${roomId}/regions`, {
      regions,
    });

    return res && res.data;
  } catch (error) {
    console.error('error updating room regions');
    throw error;
  }
};
