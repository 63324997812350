import { useEffect } from 'react';
import toast from 'react-hot-toast';

import type { EventReviewRoom } from '@inspiren-monorepo/virtual-care/api-contracts';

import { extractRoomNumberFromRoomId } from '../../../utility/helpers/id';
import { getURLSearchParams } from '../helpers/getURLSearchParams';
import { updateUrlParams } from '../helpers/updateUrlParams';
import { useEventReviewStore } from '../store/EventReviewStore';

import type { Organization, Unit } from '../../../../types';

interface UpdateUrlParams {
  orgs: Organization[];
  units?: Unit[];
  filteredRooms: EventReviewRoom[];
  orgsLoading: boolean;
  unitsLoading: boolean;
  roomsLoading: boolean;
}

export const useUpdateUrlParams = ({
  orgs,
  units,
  filteredRooms,
  orgsLoading,
  unitsLoading,
  roomsLoading,
}: UpdateUrlParams) => {
  const {
    selectedOrg,
    selectedUnit,
    selectedRoom,
    startDate,
    endDate,
    setSelectedOrg,
    setSelectedUnit,
    setSelectedRoom,
    setStartDate,
    setEndDate,
  } = useEventReviewStore();

  useEffect(() => {
    const { urlOrg, urlUnit, urlRoom, urlStart, urlEnd } = getURLSearchParams();

    if (!urlOrg || !urlUnit || !urlRoom || !urlStart || !urlEnd) {
      return;
    }

    if (
      selectedOrg?.id === urlOrg &&
      `${selectedUnit?.floor}-${selectedUnit?.name}` === urlUnit &&
      (selectedRoom?.name ||
        extractRoomNumberFromRoomId(selectedRoom?.mainId || '')) === urlRoom &&
      startDate?.toISOString() === urlStart &&
      endDate?.toISOString() === urlEnd
    ) {
      return;
    }

    if (orgsLoading || unitsLoading) {
      return;
    }

    const org = orgs.find((o) => o.id === urlOrg);

    if (!org) {
      toast.error('Invalid organization in url parameters');
      return;
    }

    const unit = units?.find((u) => `${u.floor}-${u.name}` === urlUnit);

    if (!unit) {
      toast.error('Invalid unit in url parameters');
      return;
    }

    setSelectedOrg(org);
    setSelectedUnit(unit);

    const room = filteredRooms.find(
      (r) => (r.name || extractRoomNumberFromRoomId(r.mainId)) === urlRoom,
    );

    if (!room) {
      if (!roomsLoading && filteredRooms.length) {
        toast.error('Invalid room in url parameters');
      }

      return;
    }

    const start = new Date(urlStart);
    const now = new Date();

    if (start > now) {
      toast.error('Invalid start date in url parameters');
      return;
    }

    const end = new Date(urlEnd);

    if (end < start) {
      toast.error('Invalid end date in url parameters');
      return;
    }

    setSelectedRoom(room);
    setStartDate(start);
    setEndDate(end);
  }, [
    orgs,
    units,
    filteredRooms,
    setSelectedOrg,
    setSelectedUnit,
    setSelectedRoom,
    setStartDate,
    setEndDate,
    orgsLoading,
    unitsLoading,
    roomsLoading,
  ]);

  useEffect(() => {
    updateUrlParams(
      selectedOrg,
      selectedUnit,
      selectedRoom,
      startDate,
      endDate,
    );
  }, [selectedOrg, selectedUnit, selectedRoom, startDate, endDate]);
};
