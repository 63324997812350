import { Api } from '../../../utility/client';

import type { Role } from '../../../../types';

export const putRole = async (role: Role) => {
  const res = await Api.put(`/admin/v1/roles/${role.id}`, {
    ...role,
  });

  return res.data;
};
