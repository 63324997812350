import { useEffect, useMemo, useRef, useState } from 'react';
import type { SxProps, Theme } from '@mui/material';
import { Box, Skeleton, Stack, Typography } from '@mui/material';
import { ImageNotSupported } from '@mui/icons-material';

import useFormattedTimeDifference from './hooks/useFormattedTimeDifference';

import { defaultAspectRatio } from '../../rules';
import { useZoom } from '../../utility/hooks';

interface Props {
  onClick?: () => void;
  src?: string;
  width?: number | string;
  height?: number | string;
  enableZoom?: boolean;
  sx?: SxProps<Theme>;
  privacy?: boolean;
  privEndsAt?: string;
  disabled?: boolean;
  loading?: boolean;
  offline?: boolean;
  testID?: string;
  keepAlive?: string;
}

const RoomImage = ({
  onClick,
  src,
  width = defaultAspectRatio.width,
  height = defaultAspectRatio.height,
  enableZoom = false,
  sx = {},
  privacy = false,
  privEndsAt,
  disabled = false,
  loading = false,
  offline = false,
  testID,
  keepAlive,
}: Props) => {
  const zoomHTMLElement = useRef<null | HTMLImageElement>(null);
  useZoom(enableZoom, zoomHTMLElement);

  const [isImgLoaded, setIsImgLoaded] = useState(false);

  useEffect(() => {
    if (disabled || privacy || offline || loading) {
      setIsImgLoaded(false);
    }
  }, [disabled, privacy, offline, loading, setIsImgLoaded]);

  const statusBoxSxProps = useMemo(
    () => ({
      width,
      height,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 1,
    }),
    [width, height],
  );

  const formattedOfflineTime = useFormattedTimeDifference(
    keepAlive || '',
    offline,
    true,
  );

  const formattedPrivTime = useFormattedTimeDifference(
    privEndsAt || '',
    privacy,
    false,
  );

  const handleLoadImg = () => {
    setIsImgLoaded(true);
  };

  const content = useMemo(() => {
    if (disabled) {
      return (
        <Box
          sx={(theme) => ({
            backgroundColor: theme.palette.disableAugi.main,
            color: theme.palette.disableAugi.contrastText,
            ...statusBoxSxProps,
          })}
        >
          <Stack
            direction='column'
            alignItems='center'
            sx={{ userSelect: 'none' }}
          >
            <Typography>AUGi Disabled</Typography>
            {offline && formattedOfflineTime && (
              <Typography
                variant='body2'
                mt={1}
              >{`Offline for ${formattedOfflineTime}`}</Typography>
            )}
          </Stack>
        </Box>
      );
    }

    if (offline || privacy) {
      return (
        <Box
          sx={(theme) => ({
            ...(privacy
              ? {
                  background:
                    'linear-gradient(180deg, rgba(71,109,195,1) 0%, rgba(172,193,253,1) 100%)',
                  color: theme.palette.disableAugi.contrastText,
                }
              : {
                  bgcolor: 'grey.400',
                  color: 'grey.600',
                }),
            ...statusBoxSxProps,
          })}
        >
          {privacy ? (
            <Stack direction='column' alignItems='center'>
              <Typography sx={{ userSelect: 'none', fontSize: '1.5em' }}>
                Virtual Curtain
              </Typography>
              <Typography sx={{ userSelect: 'none', fontSize: '1.5em' }}>
                {formattedPrivTime}
              </Typography>
            </Stack>
          ) : (
            <>
              <ImageNotSupported fontSize='large' />
              <Stack direction='column' alignItems='center'>
                <Typography sx={{ userSelect: 'none' }}>Offline</Typography>
                <Typography sx={{ userSelect: 'none' }}>
                  {formattedOfflineTime && `for ${formattedOfflineTime}`}
                </Typography>
              </Stack>
            </>
          )}
        </Box>
      );
    }

    if (loading) {
      return <Skeleton variant='rectangular' width={width} height={height} />;
    }

    if (src) {
      return (
        <img
          src={src}
          ref={zoomHTMLElement}
          style={{ maxWidth: '100%', maxHeight: '100%' }}
          alt=''
          onLoad={handleLoadImg}
        />
      );
    }
  }, [
    disabled,
    offline,
    privacy,
    loading,
    src,
    width,
    height,
    statusBoxSxProps,
    formattedPrivTime,
    formattedOfflineTime,
  ]);

  return (
    <Box
      sx={{
        ...sx,
        ...(src && isImgLoaded ? { textAlign: 'center' } : { width, height }),
      }}
      onClick={onClick}
      data-testid={testID}
      data-test-component='RoomImage'
    >
      {content}
    </Box>
  );
};

export default RoomImage;
