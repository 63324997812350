import { useCallback, useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { unitFields } from './constants/unitFields';
import { postUnit } from './data-access/postUnit';
import { putUnit } from './data-access/putUnit';

import { getFloors } from '../../../../utility';
import {
  extractBuildingFloorFromFloorId,
  extractOrgFromFloorId,
} from '../../../../utility/helpers/id';
import { getOrgs } from '../../data-access/getOrgs';
import { getUnits } from '../../data-access/getUnits';
import TableBase from '../TableBase';

import type { UnitFieldTypes } from './types/UnitFieldTypes';
import type { UnitUpdate } from '../../data-access/types/UnitUpdate';
import type { OnSubmitFormModal } from '../../modals/FormModalBase';

const UnitsTable = () => {
  const queryClient = useQueryClient();

  const {
    data: orgs,
    isLoading: orgsLoading,
    isError: orgsError,
  } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const { isLoading: floorsLoading, isError: floorsError } = useQuery({
    queryKey: ['floors'],
    queryFn: getFloors,
  });

  const {
    isLoading: unitsLoading,
    isError: unitsError,
    data: units,
  } = useQuery({
    queryKey: ['units'],
    queryFn: getUnits,
  });

  const handleEditSubmit: OnSubmitFormModal<UnitFieldTypes> = useCallback(
    async ({
      org,
      buildingFloor,
      name,
      displayName,
      privOpts,
      curtainFeature,
      wThresh,
      rThresh,
      nThresh,
      wnThresh,
      nStart,
      nEnd,
      disableAugi,
      imageExp,
      eventExp,
      audible,
      webFallAlertSoundLoop,
      hide,
      escFall,
      escLB,
      escOOC,
      escUnit,
      escHidden,
      escUrgent,
      escWarning,
      escBathroom,
      disableAugiAlertInterval,
      nightLow,
      bathroomAlertThreshold,
    }) => {
      try {
        const convertToNumber = (value: string | null) =>
          value !== null ? Number(value) : null;

        const data: UnitUpdate = {
          floor: `${org}-${buildingFloor}`,
          name,
          displayName,
          privOpts: privOpts?.map((o) => +o),
          privOff: !curtainFeature,
          wThresh: convertToNumber(wThresh),
          rThresh: convertToNumber(rThresh),
          nThresh: convertToNumber(nThresh),
          wnThresh: convertToNumber(wnThresh),
          nStart: convertToNumber(nStart),
          nEnd: convertToNumber(nEnd),
          disableAugi,
          imageExp: convertToNumber(imageExp),
          eventExp: convertToNumber(eventExp),
          audible,
          webFallAlertSoundLoop,
          nightLow,
          hide,
          escFall: {
            assigned: convertToNumber(escFall?.assigned),
            unit: convertToNumber(escFall?.unit),
            building: convertToNumber(escFall?.building),
          },
          escLB: {
            assigned: convertToNumber(escLB?.assigned),
            unit: convertToNumber(escLB?.unit),
            building: convertToNumber(escLB?.building),
          },
          escOOC: {
            assigned: convertToNumber(escOOC?.assigned),
            unit: convertToNumber(escOOC?.unit),
            building: convertToNumber(escOOC?.building),
          },
          escHidden: {
            assigned: convertToNumber(escHidden?.assigned),
            unit: convertToNumber(escHidden?.unit),
            building: convertToNumber(escHidden?.building),
          },
          escUrgent: {
            assigned: convertToNumber(escUrgent?.assigned),
            unit: convertToNumber(escUrgent?.unit),
            building: convertToNumber(escUrgent?.building),
          },
          escWarning: {
            assigned: convertToNumber(escWarning?.assigned),
            unit: convertToNumber(escWarning?.unit),
            building: convertToNumber(escWarning?.building),
          },
          escBathroom: {
            assigned: convertToNumber(escBathroom?.assigned),
            unit: convertToNumber(escBathroom?.unit),
            building: convertToNumber(escBathroom?.building),
          },
          escUnit,
          disableAugiAlertInterval: convertToNumber(disableAugiAlertInterval),
          bathroomAlertThreshold: convertToNumber(bathroomAlertThreshold),
        };

        await putUnit(data);

        await queryClient.invalidateQueries({
          queryKey: ['units'],
        });

        toast.success(`Successfully updated unit: ${name || displayName}`);
      } catch (error) {
        toast.error(`Error updating unit${error ? `: ${error}` : ''}`);
      }
    },
    [],
  );

  const handleAddSubmit: OnSubmitFormModal<UnitFieldTypes> = useCallback(
    async ({ org, buildingFloor, name, displayName }) => {
      try {
        const data = {
          floor: `${org}-${buildingFloor}`,
          name,
          displayName: displayName || undefined,
        };

        await postUnit(data);

        await queryClient.invalidateQueries({
          queryKey: ['units'],
        });

        toast.success(`Successfully added unit: ${name || displayName}`);
      } catch (error) {
        toast.error(`Error adding unit${error ? `: ${error}` : ''}`);
      }
    },
    [],
  );

  const data = useMemo(
    () =>
      (units || []).map(
        ({
          floor,
          privOpts,
          displayName,
          wThresh,
          rThresh,
          nThresh,
          wnThresh,
          nStart,
          nEnd,
          imageExp,
          eventExp,
          privOff,
          escFall,
          escLB,
          escOOC,
          escHidden,
          escUrgent,
          escWarning,
          escBathroom,
          disableAugiAlertInterval,
          bathroomAlertThreshold,
          ...remainingFields
        }) => ({
          org: extractOrgFromFloorId(floor),
          buildingFloor: extractBuildingFloorFromFloorId(floor),
          privOpts: privOpts?.map((o) => o.toString()) || [],
          displayName: displayName || '',
          wThresh: wThresh?.toString() || '',
          rThresh: rThresh?.toString() || '',
          nThresh: nThresh?.toString() || '',
          wnThresh: wnThresh?.toString() || '',
          nStart: nStart?.toString() || '',
          nEnd: nEnd?.toString() || '',
          imageExp: imageExp?.toString() || '',
          eventExp: eventExp?.toString() || '',
          escFall: escFall || {},
          escLB: escLB || {},
          escOOC: escOOC || {},
          escHidden: escHidden || {},
          escUrgent: escUrgent || {},
          escWarning: escWarning || {},
          escBathroom: escBathroom || {},
          curtainFeature: !privOff,
          disableAugiAlertInterval: disableAugiAlertInterval?.toString() || '',
          bathroomAlertThreshold: bathroomAlertThreshold?.toString() || '',
          ...remainingFields,
        }),
      ),
    [units],
  );

  // TODO: Add ORG_ID CONTEXT value
  const org = orgs?.find((orgRow) => orgRow.id !== 'INSPIREN');

  const fieldsToShow = !org?.bathroomSensorEnabled
    ? unitFields.filter(
        (field) =>
          field.field !== 'escBathroom' &&
          field.field !== 'bathroomAlertThreshold',
      )
    : unitFields;

  return (
    <TableBase<UnitFieldTypes>
      itemName='Unit'
      fields={fieldsToShow}
      data={data}
      getRowId={({ org, buildingFloor, name }) =>
        `${org}-${buildingFloor}-${name}`
      }
      defaultSort='org'
      loading={unitsLoading}
      modalLoading={orgsLoading || floorsLoading}
      modalError={orgsError || floorsError}
      error={unitsError}
      onEditSubmit={handleEditSubmit}
      onAddSubmit={handleAddSubmit}
    />
  );
};

export default UnitsTable;
