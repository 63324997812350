import { useMemo } from 'react';
import type {
  GridEditSingleSelectCellProps,
  GridRenderEditCellParams,
} from '@mui/x-data-grid';
import { GridEditSingleSelectCell, useGridApiContext } from '@mui/x-data-grid';
import { isEmpty, isNil } from 'lodash';

import { useUnits } from '../../../hooks/useUnits';
import { useFieldValidator } from '../hooks/useFieldValidator';

const ImportUnitSelect = (params: GridRenderEditCellParams<string>) => {
  const apiRef = useGridApiContext();

  const { id, error: validationError, disabled, colDef } = params;

  const { isLoading, data: units } = useUnits();

  const selectedOrg = apiRef.current.state.editRows[id]?.organization?.value;

  const valueOptions = useMemo(() => {
    if (isEmpty(selectedOrg) || !units) {
      return [];
    }

    return units
      .filter((unit) => unit.floor.includes(`${selectedOrg}-`))
      .map((unit) => `${unit.floor.split('-').slice(1).join('-')}-${unit.name}`)
      .sort();
  }, [units, selectedOrg]);

  const { invalidValueError } = useFieldValidator({
    skipValidation: isLoading || isNil(selectedOrg),
    params,
    valueOptions,
  });

  const selectParams: GridEditSingleSelectCellProps = {
    ...params,
    colDef: {
      ...colDef,
      valueOptions,
    },
    disabled: disabled || !selectedOrg || isLoading,
    onValueChange: () => {
      apiRef.current.setEditCellValue({
        id,
        field: 'room',
        value: '',
        debounceMs: 200,
      });
    },
    error: !!selectedOrg && (invalidValueError || !!validationError),
  };

  return <GridEditSingleSelectCell {...selectParams} />;
};

export default ImportUnitSelect;
