import { skipToken, useQuery } from '@tanstack/react-query';

import { getRoomsByOrg } from '../data-access/getRoomsByOrg';

import type { AdminToolRoom } from '../data-access/getRoomsByOrg';
import type { UseQueryOptions } from '@tanstack/react-query';

interface Variables {
  orgId?: string;
  includeDeleted?: boolean;
}

export function useOrgRooms(
  variables: Variables,
  options: Omit<
    UseQueryOptions<AdminToolRoom[], unknown, AdminToolRoom[]>,
    'queryKey' | 'queryFn'
  > = {},
) {
  const { orgId, includeDeleted = false } = variables;

  return useQuery({
    ...options,
    queryKey: ['rooms', orgId, includeDeleted],
    queryFn: orgId ? () => getRoomsByOrg(orgId, includeDeleted) : skipToken,
  });
}
