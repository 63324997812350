import type { WasabiImage } from '@inspiren-monorepo/shared-react';

import { Api } from '../../../utility/client';

import type { ViewMode } from '../../../../types';

export interface LiveImagesData {
  images: WasabiImage[];
  roomId: string;
}

export const getRoomLiveImages = async (
  roomId: string,
  viewMode: ViewMode,
  duration = 60,
): Promise<LiveImagesData> => {
  try {
    const res = await Api.get<LiveImagesData>(`/v1/images/${roomId}`, {
      params: {
        duration,
        clear: viewMode === 'telesitter',
      },
    });

    return res?.data;
  } catch (error) {
    console.error('error requesting room live image');
    throw error;
  }
};
