import { useMemo, useState } from 'react';
import {
  Box,
  Button,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
} from '@mui/material';
import { Check, KeyboardArrowDown } from '@mui/icons-material';

import { sortUnitDropdown } from '../../../utility/helpers/sortUnitDropdown';
import { useUnits } from '../hooks/useUnits';

import type { Unit } from '../../../../types';

interface Props {
  selectedUnit: string;
  onSelectedUnitChange: (unit: string) => void;
}

export const UnitDropdown = ({ selectedUnit, onSelectedUnitChange }: Props) => {
  const [modeAnchorEl, setModeAnchorEl] = useState<null | HTMLElement>(null);
  const modeOpen = Boolean(modeAnchorEl);

  const { units: allUnits } = useUnits();

  const handleSelectUnitChange = (unit: string) => {
    setModeAnchorEl(null);
    onSelectedUnitChange(unit);
  };

  /*
  {
    'building1': Unit[],
    'building2': Unit[],
  }
  */
  const group = useMemo(() => {
    const groupedUnits: { [key: string]: Unit[] } = {};
    const sortedUnits = sortUnitDropdown(allUnits);

    sortedUnits.forEach((unit) => {
      if (
        unit?.buildingDisplayName &&
        groupedUnits[unit?.buildingDisplayName]
      ) {
        groupedUnits[unit?.buildingDisplayName].push(unit);
      } else if (unit?.buildingDisplayName) {
        groupedUnits[unit?.buildingDisplayName] = [unit];
      }
    });

    return groupedUnits;
  }, [allUnits]);

  const groupedBuildingsWithUnits = useMemo(() => Object.keys(group), [group]);
  return (
    <Box sx={{ minWidth: 120 }}>
      <Button
        onClick={(e) => setModeAnchorEl(e.currentTarget)}
        endIcon={<KeyboardArrowDown />}
        variant='contained'
        id='mode-button'
        aria-controls={modeOpen ? 'mode-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={modeOpen ? 'true' : undefined}
      >
        Select Unit
      </Button>
      <Menu
        id='mode-menu'
        anchorEl={modeAnchorEl}
        open={modeOpen}
        onClose={() => setModeAnchorEl(null)}
        MenuListProps={{
          'aria-labelledby': 'mode-button',
        }}
        slotProps={{
          paper: {
            sx: { minWidth: 200 },
          },
        }}
      >
        {groupedBuildingsWithUnits.map((buildingDisplayName) => (
          <Box key={buildingDisplayName}>
            <ListSubheader>{buildingDisplayName}</ListSubheader>
            {group[buildingDisplayName].map(({ floor, name, displayName }) => (
              <MenuItem
                key={`${floor}-${name}`}
                onClick={() => handleSelectUnitChange(`Room-${floor}-${name}`)}
                selected={`Room-${floor}-${name}` === selectedUnit}
              >
                {`Room-${floor}-${name}` === selectedUnit && (
                  <ListItemIcon>
                    <Check />
                  </ListItemIcon>
                )}
                <ListItemText inset={`Room-${floor}-${name}` !== selectedUnit}>
                  {displayName || name}
                </ListItemText>
              </MenuItem>
            ))}
          </Box>
        ))}
        <Box>
          <ListSubheader>Other</ListSubheader>
          <MenuItem
            key='All'
            onClick={() => handleSelectUnitChange('CUSTOM')}
            selected={selectedUnit === 'CUSTOM'}
          >
            {selectedUnit === 'CUSTOM' && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            <ListItemText inset={selectedUnit !== 'CUSTOM'}>
              Custom View
            </ListItemText>
          </MenuItem>
        </Box>
      </Menu>
    </Box>
  );
};
