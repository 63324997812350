import { Api } from '../../../../../utility/client';

import type { Unit } from '../../../../../../types';

export const postUnit = async (
  unit: Pick<Unit, 'floor' | 'name' | 'displayName'>,
) => {
  try {
    const res = await Api.post<Unit>('/admin/v1/units', unit);
    return res?.data;
  } catch (error) {
    console.error('error adding unit');
    throw error;
  }
};
