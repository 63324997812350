import { useQuery } from '@tanstack/react-query';
import { useWatch } from 'react-hook-form';

import { getFloors } from '../../data-access/getFloors';
import { getOrgs } from '../../data-access/getOrgs';
import ControlledDropdown from '../ControlledDropdown';

import type { Control, UseFormSetValue } from 'react-hook-form';

type Props = {
  control: Control;
  setValue: UseFormSetValue<{
    org: string;
    buildingFloor: string;
  }>;
  type: 'add' | 'edit';
};

const SelectOrgFloor = ({ control, setValue, type }: Props) => {
  const selectedOrg = useWatch({ name: 'org', control });

  const { isLoading: orgsLoading, data: orgs } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const { isLoading: floorsLoading, data: floors } = useQuery({
    queryKey: ['floors'],
    queryFn: getFloors,
  });

  const loading = orgsLoading || floorsLoading;

  return (
    <>
      <ControlledDropdown
        id='org'
        label='Organization'
        items={(orgs || []).map((org) => org.id).sort()}
        control={control}
        disabled={loading || type === 'edit'}
        onChange={() => setValue('buildingFloor', '')}
      />
      <ControlledDropdown
        id='buildingFloor'
        label='Building-Floor'
        items={(floors || [])
          .filter((floor) => floor.building.includes(`${selectedOrg}-`))
          .map(
            (floor) => `${floor.building.split('-')[1]}-${floor.floorNumber}`,
          )
          .sort()}
        control={control}
        disabled={loading || selectedOrg === '' || type === 'edit'}
      />
    </>
  );
};

export default SelectOrgFloor;
