import { Autocomplete, Chip, TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import type { Control } from 'react-hook-form';

const SelectPrivOpts = ({ control }: { control: Control }) => (
  <Controller
    name='privOpts'
    control={control}
    render={({ field: { value, onChange }, fieldState: { error } }) => (
      <Autocomplete
        multiple
        id='privOpts'
        value={value}
        onChange={(_e, newValue) => onChange(newValue)}
        options={[]}
        open={false}
        freeSolo
        renderTags={(tagValue: string[], getTagProps) =>
          tagValue.map((option, index) => (
            <Chip
              variant='outlined'
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant='outlined'
            label='Virtual curtain duration options'
            placeholder='# of minutes'
            margin='dense'
            error={Boolean(error)}
            helperText={
              error && 'Privacy options must be positive whole numbers'
            }
          />
        )}
      />
    )}
  />
);

export default SelectPrivOpts;
