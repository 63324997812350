import { useCallback, useMemo, useState } from 'react';
import type { TooltipProps } from '@mui/material';
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
} from '@mui/material';
import {
  Add,
  Block,
  Bed,
  BedroomParent,
  Cached,
  Chair,
  Clear,
  ClearAll,
  Lock,
  LockOpen,
  MeetingRoom,
  MoreVert,
  PanTool,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useConfirm } from 'material-ui-confirm';

import type {
  DrawingRegion,
  RegionConfig,
} from '@inspiren-monorepo/hilq/api-contracts';

import type { CanvasAction } from '../types';

interface CanvasControlsProps {
  activeDrawingRegion?: RegionConfig;
  canInvertRegion: boolean;
  isAdminView?: boolean;
  handleAddRegion: (newRegionType: DrawingRegion) => void;
  regions: RegionConfig[];
  handleDrawingRegionChange: (
    event: React.MouseEvent<HTMLElement>,
    newDrawingRegionId: string,
  ) => void;
  canvasAction?: CanvasAction;
  handleCanvasActionChange: (newCanvasAction?: CanvasAction) => void;
  handleRegionHover: (regionId?: string) => void;
  handleClearSelectedRegion: () => void;
  handleClearAllRegions: () => void;
  handleCancel: () => void;
  handleRegionUpdate: () => void;
  handleLockExitIgnore: () => void;
  handleInvertRegion: () => void;
  lockExitIgnore: boolean;
  isLoading: boolean;
  allPointsEmpty: boolean;
  width: number;
}

const regionIcons = {
  bed: {
    icon: <Bed />,
    tooltip: 'Bed',
  },
  bedEdge: {
    icon: <BedroomParent />,
    tooltip: 'Bed Edge',
  },
  chair: {
    icon: <Chair />,
    tooltip: 'Chair',
  },
  ignore: {
    icon: <Block />,
    tooltip: 'Ignore',
  },
  exit: {
    icon: <MeetingRoom />,
    tooltip: 'Exit',
  },
};

export const CanvasControls = ({
  activeDrawingRegion,
  isAdminView,
  canInvertRegion,
  handleAddRegion,
  regions,
  handleDrawingRegionChange,
  canvasAction,
  handleCanvasActionChange,
  handleClearAllRegions,
  handleClearSelectedRegion,
  handleCancel,
  handleLockExitIgnore,
  handleRegionUpdate,
  handleRegionHover,
  handleInvertRegion,
  isLoading,
  lockExitIgnore,
  allPointsEmpty,
  width,
}: CanvasControlsProps) => {
  const confirm = useConfirm();
  const [addAnchor, setAddAnchor] = useState<null | HTMLElement>(null);
  const [moreAnchor, setMoreAnchor] = useState<null | HTMLElement>(null);
  const [addMenuOpen, setAddMenuOpen] = useState(false);

  const onChangeCanvasAction = useCallback(
    (event: React.MouseEvent<HTMLElement>, value?: string) => {
      if (value === 'add') {
        setAddAnchor(event.currentTarget);
      }

      setAddMenuOpen(value === 'add');
      handleCanvasActionChange(value as CanvasAction);
    },
    [],
  );

  const onClickAwayMenu = useCallback(() => {
    setAddMenuOpen(false);
    handleCanvasActionChange();
  }, []);

  const handleConfirm = useCallback(async () => {
    try {
      await confirm({
        title: "WARNING! You're about to save this room without any regions!",
      });

      handleRegionUpdate();
    } catch {
      // Do nothing. `confirm` will throw if the user cancels, which
      // is fine
    }
  }, [handleRegionUpdate]);

  const addRegion = useCallback((regionType: DrawingRegion) => {
    handleAddRegion(regionType);
    setAddMenuOpen(false);
  }, []);

  const getRegionIcon = (
    regionType: DrawingRegion,
    placement: TooltipProps['placement'],
    disableHoverListener?: boolean,
  ) => (
    <Tooltip
      describeChild
      disableHoverListener={disableHoverListener}
      title={regionIcons[regionType].tooltip}
      placement={placement}
    >
      {regionIcons[regionType].icon}
    </Tooltip>
  );

  const { shownRegions, additionalRegions } = useMemo(
    () => ({
      shownRegions: regions.length > 7 ? regions.slice(0, 6) : regions,
      additionalRegions: regions.length > 7 ? regions.slice(6) : [],
    }),
    [regions],
  );

  const handleShowMore = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      setMoreAnchor(e.currentTarget);
    },
    [],
  );

  // const handleCloseShowMore = useCallback(() => { setMoreAnchor(null); }, []);

  let timeoutId: NodeJS.Timeout | null = null;

  const handleCloseShowMore = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      setMoreAnchor(null);
    }, 0);
  };

  const handleMenuEnter = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
  };

  const handleMenuClose = () => {
    setMoreAnchor(null);
  };

  const shortcutKeyComponent = (shortcutKey: string) => (
    <span
      style={{
        padding: '3px 7px',
        marginLeft: '5px',
        borderRadius: '3px',
        backgroundColor: 'rgba(138, 138, 138)',
      }}
    >
      <b>{shortcutKey}</b>
    </span>
  );

  const tooltipWithShortcut = (title: string, shortcutKey: string) => (
    <span
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: '5px',
      }}
    >
      {title} {shortcutKeyComponent(shortcutKey)}
    </span>
  );

  const addRegionTooltip = useMemo(
    () => (
      <>
        <div>
          <b>Add Region</b>
        </div>
        <ul
          style={{ paddingInlineStart: '10px', margin: '0', minWidth: '65px' }}
        >
          <li>{tooltipWithShortcut('Bed', '0')}</li>
          {isAdminView && !lockExitIgnore && (
            <li>{tooltipWithShortcut('Exit', '1')}</li>
          )}
          <li>{tooltipWithShortcut('Chair', '3')}</li>
          {isAdminView && !lockExitIgnore && (
            <li>{tooltipWithShortcut('Ignore', '6')}</li>
          )}
        </ul>
      </>
    ),
    [isAdminView, lockExitIgnore],
  );

  const selectedRegionTooltip = useMemo(
    () => (
      <>
        <div>
          <b>Selected Region</b>
        </div>
        <div>
          Hold {shortcutKeyComponent('Shift')} to add points sequentially.
        </div>
      </>
    ),
    [],
  );

  return (
    <Paper sx={{ width: `${width}px`, height: '54px' }} elevation={3}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        height='100%'
        sx={{ padding: 2 }}
      >
        <Box>
          <ToggleButtonGroup
            color='primary'
            value={canvasAction}
            exclusive
            onChange={onChangeCanvasAction}
            aria-label='Platform'
            size='small'
          >
            <Tooltip describeChild title={tooltipWithShortcut('Move', 'M')}>
              <ToggleButton value='move'>
                <PanTool />
              </ToggleButton>
            </Tooltip>
            <Tooltip
              describeChild
              title={addRegionTooltip}
              disableHoverListener={addMenuOpen}
            >
              <ToggleButton value='add'>
                <Add />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
          <ClickAwayListener
            mouseEvent='onMouseDown'
            touchEvent='onTouchStart'
            onClickAway={onClickAwayMenu}
          >
            <Menu
              open={addMenuOpen}
              anchorEl={addAnchor}
              slotProps={{
                root: {
                  sx: {
                    zIndex: 90000000000,
                    pointerEvents: 'none',
                  },
                },
                paper: {
                  sx: {
                    pointerEvents: 'auto',
                  },
                },
              }}
            >
              {Object.keys(regionIcons).map(
                (regionType) =>
                  (isAdminView ||
                    (regionType !== 'exit' && regionType !== 'ignore')) && (
                    <MenuItem
                      key={regionType}
                      onClick={() => addRegion(regionType as DrawingRegion)}
                    >
                      {getRegionIcon(regionType as DrawingRegion, 'right')}
                    </MenuItem>
                  ),
              )}
            </Menu>
          </ClickAwayListener>
        </Box>
        <Box sx={{ minWidth: '80px' }}>
          <Tooltip
            describeChild
            title={tooltipWithShortcut('Clear All Regions', 'C')}
          >
            <IconButton color='error' onClick={handleClearAllRegions}>
              <ClearAll />
            </IconButton>
          </Tooltip>
          {canInvertRegion && (
            <Tooltip
              describeChild
              title={tooltipWithShortcut('Invert Ignore Region', 'I')}
            >
              <IconButton
                color='info'
                onClick={handleInvertRegion}
                value='invert'
              >
                <Cached />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <Stack direction='row' sx={{ minWidth: '80px' }}>
          <Tooltip title={selectedRegionTooltip}>
            <Box
              sx={{
                display: 'flex',
                border: '1px dashed rgba(0, 0, 0, 0.12)',
                width: '40px',
                height: '40px',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'primary.main',
              }}
            >
              {activeDrawingRegion &&
                regionIcons[activeDrawingRegion.type].icon}
            </Box>
          </Tooltip>
          {activeDrawingRegion && (
            <Tooltip
              describeChild
              title={tooltipWithShortcut('Remove Selected Region', 'X')}
            >
              <IconButton
                color='error'
                size='small'
                onClick={handleClearSelectedRegion}
              >
                <Clear />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <Box sx={{ minWidth: '274px' }}>
          <ToggleButtonGroup
            color='primary'
            value={activeDrawingRegion?.id}
            exclusive
            onChange={handleDrawingRegionChange}
            aria-label='Platform'
            size='small'
          >
            {shownRegions.map((region) => (
              <ToggleButton
                value={region.id || ''}
                key={region.id}
                onMouseOver={() => handleRegionHover(region.id)}
                onMouseOut={() => handleRegionHover()}
              >
                {getRegionIcon(region.type, 'bottom')}
              </ToggleButton>
            ))}
            {additionalRegions.length > 0 && (
              <ToggleButton
                value='more'
                onMouseEnter={handleShowMore}
                onMouseLeave={handleCloseShowMore}
              >
                <MoreVert />
              </ToggleButton>
            )}
          </ToggleButtonGroup>
          {additionalRegions.length > 0 && (
            <Menu
              anchorEl={moreAnchor}
              open={Boolean(moreAnchor)}
              onClose={handleMenuClose}
              MenuListProps={{
                onMouseLeave: handleCloseShowMore,
                onMouseEnter: handleMenuEnter,
              }}
              slotProps={{
                root: {
                  sx: {
                    pointerEvents: 'none',
                    zIndex: 90000000000,
                  },
                },
                paper: {
                  sx: {
                    pointerEvents: 'auto',
                  },
                },
              }}
            >
              {additionalRegions.map((region) => (
                <MenuItem
                  sx={{ paddingLeft: '10px', paddingRight: '10px' }}
                  selected={activeDrawingRegion?.id === region.id}
                  onClick={(e) => handleDrawingRegionChange(e, region.id || '')}
                  key={region.id}
                  onMouseOver={() => handleRegionHover(region.id)}
                  onMouseOut={() => handleRegionHover()}
                >
                  {getRegionIcon(region.type, 'right')}
                </MenuItem>
              ))}
            </Menu>
          )}
        </Box>
        <Stack direction='row' alignItems='center'>
          {isAdminView && (
            <Tooltip
              title={
                lockExitIgnore
                  ? 'Unlock Exit and Ignore Regions'
                  : 'Lock Exit and Ignore Regions'
              }
            >
              <IconButton onClick={handleLockExitIgnore} color='secondary'>
                {lockExitIgnore ? <Lock /> : <LockOpen />}
              </IconButton>
            </Tooltip>
          )}
        </Stack>
        <Stack direction='row' spacing={2}>
          <Button
            variant='outlined'
            color='warning'
            sx={{ fontSize: 14, width: '110px' }}
            onClick={handleCancel}
            disabled={isLoading}
          >
            Cancel
          </Button>
          <LoadingButton
            variant='contained'
            color='secondary'
            sx={{ fontSize: 14, width: '110px' }}
            onClick={allPointsEmpty ? handleConfirm : handleRegionUpdate}
            loading={isLoading}
            disabled={isLoading}
            loadingPosition='end'
          >
            Update
          </LoadingButton>
        </Stack>
      </Stack>
    </Paper>
  );
};
