import { useQuery } from '@tanstack/react-query';

import { getUnitsByOrg } from '../data-access/getUnitsForOrg';

import type { Unit } from '../../../../types';
import type { UseQueryResult } from '@tanstack/react-query';

type UseOrganizationRolesResult = UseQueryResult<Unit[], unknown>;

export const useOrganizationUnits = (
  orgId?: string,
): UseOrganizationRolesResult =>
  useQuery({
    queryKey: ['org', orgId, 'units'],
    queryFn: () => getUnitsByOrg(orgId!),
    enabled: !!orgId,
  });
