import { useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import * as yup from 'yup';

import TableBase from './TableBase';

import { postBuilding, putBuilding } from '../../../utility';
import { timeZones, usaStates } from '../../../utility/constants';
import { getBuildings } from '../data-access/getBuildings';
import { getOrgs } from '../data-access/getOrgs';

import type { OnSubmitFormModal } from '../modals/FormModalBase';
import type { DataFields } from '../types/DataFields';

export type FieldTypes = {
  id: string;
  org: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  displayName: string;
  tz: string;
};

const fields: DataFields<FieldTypes> = [
  {
    field: 'id',
    label: 'ID',
    width: 150,
    editType: 'text',
    editable: false,
    schema: yup
      .string()
      .required('You must provide an ID')
      .matches(
        /^[\da-z]+$/i,
        'ID can only include letter and number characters',
      ),
  },
  {
    field: 'org',
    label: 'Organization',
    width: 150,
    editType: 'special',
    schema: yup.string().required('Organization is required'),
  },
  {
    field: 'displayName',
    label: 'Display Name',
    width: 200,
    editType: 'text',
    schema: yup.string().optional(),
  },
  {
    field: 'address',
    label: 'Street Address',
    width: 200,
    editType: 'text',
    schema: yup.string().required('Street address is required'),
  },
  {
    field: 'city',
    label: 'City',
    width: 120,
    editType: 'text',
    schema: yup.string().required('City is required'),
  },
  {
    field: 'state',
    label: 'State',
    width: 120,
    editType: 'select',
    options: usaStates,
    schema: yup.string().required('State is required'),
  },
  {
    field: 'zip',
    label: 'ZIP Code',
    width: 120,
    editType: 'text',
    schema: yup.string().required('ZIP Code is required'),
  },
  {
    field: 'country',
    label: 'Country',
    width: 120,
    editType: 'text',
    disabled: true,
    initialValue: 'USA',
    schema: yup.string().required('Country is required'),
  },
  {
    field: 'tz',
    label: 'Time Zone',
    width: 200,
    editType: 'select',
    options: timeZones,
    schema: yup.string().optional(),
  },
];

const RoomsTable = () => {
  const queryClient = useQueryClient();

  const { isLoading: orgsLoading, isError: orgsError } = useQuery({
    queryKey: ['orgs'],
    queryFn: getOrgs,
  });

  const {
    isLoading: buildingsLoading,
    isError: buildingsError,
    data: buildings,
  } = useQuery({
    queryKey: ['buildings'],
    queryFn: getBuildings,
  });

  const data = useMemo(() => buildings || [], [buildings]);

  const handleEditSubmit: OnSubmitFormModal<FieldTypes> = async (building) => {
    try {
      await putBuilding(building);

      await queryClient.invalidateQueries({
        queryKey: ['buildings'],
      });

      toast.success(`Successfully updated building ${building.id}`);
    } catch (error) {
      toast.error(`Error updating building${error ? `: ${error}` : ''}`);
    }
  };

  const handleAddSubmit: OnSubmitFormModal<FieldTypes> = async (building) => {
    try {
      await postBuilding(building);

      await queryClient.invalidateQueries({
        queryKey: ['buildings'],
      });

      toast.success(`Successfully added building ${building.id}`);
    } catch (error) {
      toast.error(`Error adding building${error ? `: ${error}` : ''}`);
    }
  };

  return (
    <TableBase<FieldTypes>
      itemName='Building'
      fields={fields}
      data={data}
      loading={buildingsLoading}
      error={buildingsError}
      modalLoading={orgsLoading}
      modalError={orgsError}
      onEditSubmit={handleEditSubmit}
      onAddSubmit={handleAddSubmit}
      getRowId={({ org, id }) => `${org}-${id}`}
    />
  );
};

export default RoomsTable;
